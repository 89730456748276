// Nav.js

import React, { useState } from "react";
import { useAuth } from "../../contexts/AuthContext.js";
import {
  NavContainer,
  NavBrand,
  NavList,
  NavItem,
  NavLink,
  CTAButton,
  SignUpButton,
  MenuIcon,
  IconContainer,
} from "./Nav.styled.js";

function Nav() {
  const { currentUser, logOut } = useAuth();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const username = "Username"; // Placeholder for the actual username logic

  // Toggle menu visibility
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <NavContainer>
      <NavBrand href="/">Festiv</NavBrand>
      <NavList className={isMenuOpen ? "active" : ""}>
        <NavItem>
          <NavLink href="/festivals">Festivals</NavLink>
        </NavItem>
        {currentUser ? (
          <>
            <NavItem>
              <CTAButton as="button" onClick={logOut}>
                Log Out
              </CTAButton>
            </NavItem>
          </>
        ) : (
          <>
            <NavItem>
              <NavLink href="/login">Log In</NavLink>
            </NavItem>
            <NavItem>
              <SignUpButton to="/signup">Sign Up</SignUpButton>
            </NavItem>
          </>
        )}
      </NavList>
      {/* Icon Container for MenuIcon */}
      <IconContainer onClick={toggleMenu}>
        <MenuIcon />
      </IconContainer>
    </NavContainer>
  );
}

export default Nav;
