import styled from "styled-components";
import { Link } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import MuiMenuIcon from "@mui/icons-material/Menu";

export const NavContainer = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background: #fff;
  position: relative; // Added to position the MenuIcon absolutely within

  @media (max-width: 768px) {
    padding: 1rem; // Reduced padding for smaller screens
  }
`;

export const NavBrand = styled.a`
  font-size: 1.5rem;
  font-weight: 900;
  text-decoration: none;
  color: #000;

  &:hover {
    color: #606060;
  }
`;

export const MenuIcon = styled(MuiMenuIcon)`
  //empty
`;

export const NavList = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  align-items: center;
  box-sizing: border-box; // Include padding and borders in the width and height

  @media (max-width: 768px) {
    display: none; // Initially hidden on mobile
    flex-direction: column; // Stack items vertically
    position: absolute; // Position it over the content
    top: 50px; // Adjust top position to be just below the NavContainer
    left: 0;
    right: 0;
    background: #fff;
    width: 100%;
    padding: 1rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); // Add drop shadow
    z-index: 10000; // Ensure it's above other content

    &.active {
      display: flex;
    }
  }
`;

export const NavItem = styled.li`
  margin: 0 1rem;
  font-size: 1rem;

  &:last-child {
    margin-right: 0;
  }

  @media (max-width: 768px) {
    margin: 0.5rem 0; // Adjust margins for stacked items
  }
`;

export const NavLink = styled.a`
  color: #333;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

export const CTAButton = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 6.8125rem;
  height: 2.0625rem;
  flex-shrink: 0;
  border-radius: 0.3125rem;
  border: 2px solid #000;
  background-color: transparent;
  color: #000;
  text-align: center;
  font-family: ${(props) => props.theme.fonts.primary};
  font-size: 1.125rem;
  font-weight: 400;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    background-color: #000;
    color: #fff;
  }
`;

export const SignUpButton = styled(Link)`
  display: inline-flex; // Ensures proper alignment and spacing
  align-items: center;
  justify-content: center;
  background-color: #000000;
  color: #ffffff;
  border: none;
  width: 6.8125rem;
  height: 2.0625rem;
  cursor: pointer;
  border-radius: 5px;
  font-weight: bold;
  text-decoration: none; // Remove default link underline
  text-align: center; // Center the text inside the button

  &:hover {
    background-color: #e8e8e8;
    color: #000000;
    text-decoration: none; // Remove default link underline on hover
  }

  &:focus,
  &:active {
    outline: none; // Remove default focus outline
    text-decoration: none; // Remove default link underline on focus/active
  }
`;

export const UserIcon = styled(PersonIcon)`
  margin-right: 8px;
`;

export const IconContainer = styled.div`
  display: none; // Hide by default

  @media (max-width: 768px) {
    display: block; // Show only on mobile screens
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 15;
    cursor: pointer;
  }
`;
