import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useAuth } from "../../contexts/AuthContext";
import { Link } from "react-router-dom";

import {
  ButtonGroup,
  PriorityButton,
  QuestionText,
  SpaceText,
  StyledBookmarkBorderIcon,
  StyledBookmarkIcon,
  StyledStarBorderRoundedIcon,
  StyledStarRoundedIcon,
  StyledLoginLink,
  UserFilmsDiv,
} from "./UserFilms.styled";

function UserFilms({ filmId, title, priority, onPriorityChange }) {
  const { token, userId } = useAuth();
  const [currentPriority, setCurrentPriority] = useState(priority);

  useEffect(() => {
    setCurrentPriority(priority);
  }, [priority]);

  const handlePriorityChange = async (newPriority) => {
    let priorityToSend = newPriority;

    if (newPriority === currentPriority) {
      setCurrentPriority(0);
      priorityToSend = 0;
    } else {
      setCurrentPriority(newPriority);
    }

    if (process.env.NODE_ENV === "development") {
      console.log("Selected Priority:", priorityToSend);
    }

    // Use the token directly here, instead of fetching it anew.
    if (token) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/api/userfilms`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              film_id: filmId,
              priority: priorityToSend, // Use priorityToSend here
            }),
          },
        );

        if (response.ok) {
          const data = await response.json();
          setCurrentPriority(data.priority);
        } else if (response.status === 404) {
          setCurrentPriority(5);
        } else {
          setCurrentPriority(5);
          if (process.env.NODE_ENV === "development") {
            console.log("failed to set priority");
          }
        }
      } catch (error) {
        console.error("Error setting priority:", error);
        setCurrentPriority(5);
      }
    }
    onPriorityChange(filmId, priorityToSend);
  };

  const isUserLoggedIn = token && userId;

  return (
    <UserFilmsDiv>
      {isUserLoggedIn ? (
        <QuestionText>Do you want to see this movie?</QuestionText>
      ) : (
        <QuestionText>
          <StyledLoginLink to="/login">Log In</StyledLoginLink> to set your
          Priorities!
        </QuestionText>
      )}{" "}
      <ButtonGroup>
        <PriorityButton
          buttonType="maybe"
          selected={currentPriority === 1}
          onClick={() => handlePriorityChange(1)}
          disabled={!userId || !token}
        >
          {currentPriority === 1 ? (
            <StyledBookmarkIcon />
          ) : (
            <StyledBookmarkBorderIcon />
          )}
          Maybe
        </PriorityButton>
        <PriorityButton
          buttonType="definitely"
          selected={currentPriority === 2}
          onClick={() => handlePriorityChange(2)}
          disabled={!userId || !token}
        >
          {currentPriority === 2 ? (
            <StyledStarRoundedIcon />
          ) : (
            <StyledStarBorderRoundedIcon />
          )}
          Definitely
        </PriorityButton>
      </ButtonGroup>
      <SpaceText>&nbsp;</SpaceText>
    </UserFilmsDiv>
  );
}

UserFilms.propTypes = {
  filmId: PropTypes.number.isRequired,
  userId: PropTypes.string.isRequired,
  priority: PropTypes.number.isRequired,
  onPriorityChange: PropTypes.func.isRequired,
};

export default UserFilms;
