import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import { isEqual } from "lodash";
import {
  UserScheduleContainer,
  UserScheduleInfo,
  ScheduleDay,
  ScheduleDayInfo,
  ScheduleDayCalendar,
  ScheduleFilmListItem,
  ScheduleFilmListItemColumn,
  StartTimeHeader,
  EndTimeText,
  FilmImage,
  DurationText,
  VenueInfo,
  ScreeningNumber,
  SeeMoreButton,
  LocationIcon,
  ScheduleBufferTime,
  BufferTimeInfo,
  ScheduleFilmListItemTime,
  ScheduleFilmListItemTitle,
  ScheduleFilmListItemVenue,
  DayOfWeek,
  DateText,
  UserScheduleTitle,
  ScheduledScreeningsInfo,
  ScheduleDetails,
  ScheduleId,
  LastUpdated,
  FilmCount,
  StyledButton,
  IconButton,
  CopyIcon,
  UrlContainer,
  ButtonGroup,
} from "./UserSchedule.styled";

function UserSchedule({
  organizedSchedule,
  attendingFilms,
  priorityStats,
  festivalTimezone = "UTC",
  scheduleHash,
  lastUpdated,
  festivalName,
  scheduleToken,
  isPublicView = false,
}) {
  const [groupedSchedule, setGroupedSchedule] = useState({});
  const [unattendedFilms, setUnattendedFilms] = useState([]);
  const [isPublicUrlCopied, setIsPublicUrlCopied] = useState(false);
  const [isIcsUrlCopied, setIsIcsUrlCopied] = useState(false);
  
  const handleCopyClick = (textToCopy, buttonType) => {
    navigator.clipboard.writeText(textToCopy).then(() => {
      if (buttonType === 'publicUrl') {
        setIsPublicUrlCopied(true);
        setTimeout(() => {
          setIsPublicUrlCopied(false);
        }, 1000);
      } else if (buttonType === 'icsUrl') {
        setIsIcsUrlCopied(true);
        setTimeout(() => {
          setIsIcsUrlCopied(false);
        }, 1000);
      }
    });
  };
  

  const groupByDate = (schedule) => {
    if (!Array.isArray(schedule)) return {};

    return schedule.reduce((acc, screening) => { 
    // Group screenings by date using the festival's time zone to ensure correct ordering and display of movie times

      const dateCT = moment.tz(screening.start_time,festivalTimezone).format("YYYY-MM-DD");
      if (!acc[dateCT]) {
        acc[dateCT] = [];
      }
      acc[dateCT].push(screening);
      return acc;
    }, {});
  };

  const calculateDuration = (start_time, end_time) => {
    const startTime = moment(start_time);
    const endTime = moment(end_time);
    const duration = moment.duration(endTime.diff(startTime));
    const hours = duration.hours();
    const minutes = duration.minutes();
    return `${
      hours > 0 ? `${hours} Hour${hours > 1 ? "s" : ""} ` : ""
    }${minutes} Minute${minutes !== 1 ? "s" : ""}`;
  };

  const convertBufferTimeToMinutes = (bufferTime) => {
    // Check if bufferTime is not a string or is an empty string
    if (typeof bufferTime !== "string" || !bufferTime.trim()) {
      return 0;
    }

    // Split the bufferTime string by spaces and filter out any empty strings
    const timeParts = bufferTime.split(" ").filter(Boolean);
    let minutes = 0;

    for (let i = 0; i < timeParts.length; i += 2) {
      const value = parseInt(timeParts[i], 10);
      // Check if the unit of time (hours or minutes) exists before using startsWith
      const unit = timeParts[i + 1] || "";

      if (unit.startsWith("hour")) {
        minutes += value * 60;
      } else if (unit.startsWith("minute")) {
        minutes += value;
      }
    }

    return minutes;
  };

  useEffect(() => {
    if (Array.isArray(organizedSchedule)) {
      const grouped = groupByDate(organizedSchedule);
      setGroupedSchedule(grouped);
    }
  }, [organizedSchedule]);

  useEffect(() => {
    if (process.env.NODE_ENV === "development") {
      console.log("Received priorityStats:", priorityStats.all);
    }

    if (Array.isArray(organizedSchedule) && priorityStats.all) {
      const attendedFilmTitles = new Set(
        organizedSchedule.map((film) => film.film_title),
      );
      const unattended = priorityStats.all.filter(
        (film) => !attendedFilmTitles.has(film.title),
      );

      if (process.env.NODE_ENV === "development") {
        console.log("Filtered unattended films:", unattended);
      }

      if (!isEqual(unattended, unattendedFilms)) {
        setUnattendedFilms(unattended);
      }
    }
  }, [organizedSchedule, priorityStats, unattendedFilms]);

  // Define the Share URLs
  const baseURL = window.location.origin;
  const publicScheduleURL = `${baseURL}/schedule/${scheduleToken}`;
  const icsSubscribeURL = `${process.env.REACT_APP_API_BASE_URL}/api/schedule/id/${scheduleToken}.ics`;

  return (
    <UserScheduleContainer>
      <UserScheduleInfo>
        <div>
          <UserScheduleTitle>
            {isPublicView ? "Shared Schedule" : `Your ${festivalName} Schedule`}
          </UserScheduleTitle>
          <ScheduledScreeningsInfo>
            {isPublicView
              ? `Scheduled to see ${attendingFilms} Screenings`
              : `You are scheduled to see ${attendingFilms} Screenings`}{" "}
          </ScheduledScreeningsInfo>
        </div>
        {!isPublicView && (
          <ScheduleDetails>
            {/* <ScheduleId>Schedule ID: {scheduleHash}</ScheduleId> */}
            <LastUpdated>
              Last Updated:{" "}
              {moment
                .tz(lastUpdated, "UTC")
                .tz(moment.tz.guess())
                .format("MMMM Do YYYY @ h:mm:ss a")}
            </LastUpdated>
            {/* <p>
              Public Schedule Link:{" "}
              <UrlContainer>{publicScheduleURL}</UrlContainer>
            </p> */}
            <ButtonGroup>
              <StyledButton
                className={`outline ${isPublicUrlCopied ? "copied" : ""}`}
                onClick={() => handleCopyClick(publicScheduleURL, "publicUrl")}
              >
                {isPublicUrlCopied ? "Copied!" : "Copy Public Share Link"}
              </StyledButton>

              <StyledButton
                className={`outline ${isIcsUrlCopied ? "copied" : ""}`}
                onClick={() => handleCopyClick(icsSubscribeURL, "icsUrl")}
              >
                {isIcsUrlCopied ? "Copied!" : "Copy Schedule Feed (iCal)"}
              </StyledButton>
            </ButtonGroup>
          </ScheduleDetails>
        )}
      </UserScheduleInfo>
      {Object.keys(groupedSchedule)
        .sort()
        .map((date) => (
          <ScheduleDay key={date}>
            <ScheduleDayInfo>
              <DateText>
                {moment(date).format("dddd")}, {moment(date).format("MMMM DD")}
              </DateText>
              {/* <FilmCount>4 Films</FilmCount> */}
            </ScheduleDayInfo>
            <ScheduleDayCalendar>
              {groupedSchedule[date].map((screening, index, array) => {
                const startTime = moment(screening.start_time).tz(
                  festivalTimezone,
                );
                const endTime = moment(screening.end_time).tz(festivalTimezone);
                const formattedStartTime = startTime.format("h:mm A");
                const formattedEndTime = endTime.format("h:mm A");

                let bufferTime = null;
                if (index > 0) {
                  const previousEndTime = moment(array[index - 1].end_time).tz(
                    festivalTimezone,
                  );
                  const currentStartTime = moment(screening.start_time).tz(
                    festivalTimezone,
                  );
                  const minutesDiff = currentStartTime.diff(
                    previousEndTime,
                    "minutes",
                  );
                  const hours = Math.floor(minutesDiff / 60);
                  const minutes = minutesDiff % 60;
                  bufferTime = `${
                    hours > 0 ? `${hours} hour${hours > 1 ? "s" : ""} ` : ""
                  }${minutes} minutes until next screening`;
                }

                return (
                  <>
                    {bufferTime && (
                      <ScheduleBufferTime
                        key={`${screening.screening_id}-buffer`}
                      >
                        <BufferTimeInfo
                          bufferDuration={convertBufferTimeToMinutes(
                            bufferTime,
                          )}
                        >
                          {bufferTime}
                        </BufferTimeInfo>
                      </ScheduleBufferTime>
                    )}
                    <ScheduleFilmListItem key={screening.screening_id}>
                      <ScheduleFilmListItemColumn>
                        <StartTimeHeader>{formattedStartTime}</StartTimeHeader>
                        <EndTimeText>Ends {formattedEndTime}</EndTimeText>
                      </ScheduleFilmListItemColumn>
                      <ScheduleFilmListItemColumn>
                        <FilmImage
                          src={
                            screening.film_image ||
                            "https://dummyimage.com/224x137/000/aaa"
                          }
                          alt={screening.film_title}
                        />
                      </ScheduleFilmListItemColumn>
                      <ScheduleFilmListItemColumn>
                        <VenueInfo>
                          <LocationIcon /> {screening.venue_name}
                        </VenueInfo>
                        <ScheduleFilmListItemTitle>
                          {screening.film_title}
                        </ScheduleFilmListItemTitle>
                        <DurationText>
                          {calculateDuration(
                            screening.start_time,
                            screening.end_time,
                          )}
                        </DurationText>
                      </ScheduleFilmListItemColumn>
                      {/* <ScheduleFilmListItemColumn>
                        <ScreeningNumber>
                          Screening #{calculateScreeningNumber(screening)}
                        </ScreeningNumber>
                        <ScreeningNumber>Screening #6</ScreeningNumber>
                        <SeeMoreButton href="#">See More</SeeMoreButton>
                      </ScheduleFilmListItemColumn> */}
                    </ScheduleFilmListItem>
                  </>
                );
              })}
            </ScheduleDayCalendar>
          </ScheduleDay>
        ))}
      {!isPublicView && unattendedFilms.length > 0 && (
        <div>
          <h3>Films You Couldn't Attend</h3>
          <ul>
            {unattendedFilms.map((film, index) => (
              <li key={index}>
                {film.title} (Priority {film.priority})
              </li>
            ))}
          </ul>
        </div>
      )}
    </UserScheduleContainer>
  );
}

export default UserSchedule;

UserSchedule.propTypes = {
  organizedSchedule: PropTypes.array.isRequired,
  attendingFilms: PropTypes.number.isRequired,
  priorityStats: PropTypes.shape({
    attending: PropTypes.objectOf(PropTypes.number),
    total: PropTypes.objectOf(PropTypes.number),
    all: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  festivalTimezone: PropTypes.string,
  scheduleHash: PropTypes.string,
  lastUpdated: PropTypes.string,
  festivalName: PropTypes.string,
};
