import styled from "styled-components";
import { Link } from "react-router-dom";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

export const FilmListItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  /* padding-right: 2rem; */
  border-radius: 10px;
  background-color: ${(props) =>
    props.isOdd
      ? props.theme.colors.listItemBg1
      : props.theme.colors.listItemBg2};
  margin-bottom: 10px;
  font-family: ${(props) => props.theme.fonts.primary};

  &:hover {
    filter: brightness(95%); // Add hover effect to darken
  }

  .no-link {
    cursor: default; // Reset cursor for the radio button area
  }

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 0.5rem; // Reduce padding for smaller screens
    padding-right: 0.5rem;
  }
`;

export const FilmListItemLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  transition: filter 150ms linear;

  &:hover {
    filter: brightness(95%);
  }
`;

export const PriorityAndImageContainer = styled.div`
  display: flex;
  align-items: center;
  order: 2;

  @media (max-width: 768px) {
    order: 1; // Film image first
    width: 100%;
    margin-bottom: 0.5rem;

    img {
      width: 100%; // Resize image for smaller screens
      height: auto;
      margin-left: 0;
    }
  }
`;

export const FilmDetailsContainer = styled.div`
  flex-grow: 1;
  margin-left: 1rem;
  text-align: left;
  font-weight: ${(props) => props.theme.fontWeights.normal};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 30px;
  order: 3;

  @media (max-width: 768px) {
    order: 2; // Film details second
    margin: 0 0.5rem;
  }
`;

export const UserFilmsContainer = styled.div`
  display: flex;
  align-items: center;
  order: 1;

  @media (max-width: 768px) {
    margin: 1rem 0.5rem;
    order: 3;
    border-top: 1px #cfcfcf solid;
  }
`;

export const ScreeningsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  order: 4;

  @media (max-width: 768px) {
    order: 4; // ScreeningsContainer last
    flex-direction: row;
    align-items: center;
    margin: 0 0.5rem;
    justify-content: space-between;
  }
`;

export const ViewMoreButton = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => props.hasExternalUrl ? '8.5rem' : '7rem'};
  height: 2.2rem;
  flex-shrink: 0;
  border-radius: 0.3125rem;
  border: 2px solid #000;
  background-color: transparent;
  color: #000;
  text-align: center;
  font-family: ${(props) => props.theme.fonts.primary};
  font-size: 1.125rem;
  font-weight: 400;
  text-decoration: none;

  &:hover {
    background-color: #000;
    color: #fff;
  }

  @media (max-width: 768px) {
    width: auto; // Adjust width
    padding: 0 0.75rem; // Adjust padding
    font-size: 1rem;
  }
`;

export const FilmImage = styled.img`
  width: 224px;
  height: 137px;
  margin-left: 1rem;
  border-radius: 5px;
  object-fit: cover;

  &:hover {
    filter: brightness(90%);
  }

  @media (max-width: 768px) {
    width: 100%; // Adjust image size
    height: auto;
  }
`;

export const FilmTitle = styled.h3`
  color: ${(props) => props.theme.colors.textPrimary};
  font-size: ${(props) => props.theme.fontSizes.h3};
  margin: 0.2rem 0;
  font-weight: 900;
  text-decoration: none;

  @media (max-width: 768px) {
    font-size: 1.2rem; // Adjust font size
  }
`;

export const FilmParagraph = styled.p`
  color: ${(props) => props.theme.colors.textSecondary};
  font-size: ${(props) => props.theme.fontSizes.small};
  margin: 0.2rem 0;

  @media (max-width: 768px) {
    font-size: 0.9rem; // Adjust font size
    /* display: none; */
  }
`;

export const ScreeningsParagraph = styled(FilmParagraph)`
  font-weight: bold;
  text-align: right;
  margin: 1rem 0;
`;

export const ButtonText = styled.span`
  margin-right: 5px; // Adjust the spacing as needed
`;

export const StyledOpenInNewIcon = styled(OpenInNewIcon)`
  margin-left: 8px; // Add spacing to the left of the icon
`;
