import styled from "styled-components";
import AddCircleOutlineIcon from "@mui/icons-material/InsertInvitation";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EventBusyIcon from "@mui/icons-material/EventBusy";

export const UnavailabilityContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 20px 0 20px;
  background-color: #e8e8e8;
  position: sticky;
  top: 75px;
  z-index: 975;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    top: 155px;
  }
`;

export const HeaderContainer = styled.div`
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  padding: 10px;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

export const ScrollableDatesContainer = styled.div`
  display: flex;
  overflow-x: auto;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 0 20px;
  padding-bottom: 20px;
`;

export const DayContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
  padding: 30px;
  border-radius: 5px;
  background-color: #ffffff;
  min-width: 180px;
`;

export const UnavailabilityDate = styled.h3`
  text-align: center;
  font-size: 18px;
  font-weight: 900;
  margin-top: 0;
  margin-bottom: 10px;
`;

export const UnavailabilityRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
`;

export const AddButton = styled.button`
  background-color: #e8e8e8;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin: 5px;
  font-weight: 700;

  &:hover {
    background-color: #e0e0e0;
  }

  &:disabled {
    background-color: #e0e0e0;
    color: #c0c0c0;
    cursor: not-allowed;
  }
`;

export const DeleteButton = styled.button`
  background-color: #f44336;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
`;

export const IconButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  margin: 5px;

  &:hover {
    background-color: #e0e0e0;
  }

  &:disabled {
    cursor: not-allowed;
    color: #c0c0c0;
  }
`;

export const AddIcon = styled(AddCircleOutlineIcon)`
  color: #4caf50;
`;

export const CancelIcon = styled(CloseIcon)`
  color: #f44336;
`;

export const DeleteIcon = styled(DeleteForeverIcon)`
  color: #f44336;
  font-size: 20px !important;
`;

export const SaveIcon = styled(CheckIcon)`
  color: #4caf50;
`;

export const BusyIcon = styled(EventBusyIcon)`
  color: #000;
  opacity: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  margin: 5px;
  font-size: 20px !important;
`;

export const AvailableAllDay = styled.p`
  color: rgba(0, 0, 0, 0.5);
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin: 6px;
`;

export const UnavailabilityRowContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const TempUnavailabilityRowContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: 100%;
`;

export const ErrorMessage = styled.div`
  background-color: #ffe2e2;
  color: red;
  padding: 10px 10px;
  border-radius: 3px;
  border: 1px #ff0000 solid;
  font-size: 14px;
  margin-top: 5px;
  text-align: center;
`;
