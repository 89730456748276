import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import moment from "moment-timezone";
import UserFilms from "../user/UserFilms";
import { useAuth } from "../../contexts/AuthContext"; // Import useAuth hook
import {
  FilmDetailsContainer,
  FilmImage,
  ScreeningsList,
  FilmTitle,
} from "./FilmDetails.styled";

function FilmDetails() {
  const { filmId } = useParams();
  const [film, setFilm] = useState(null);
  const [screenings, setScreenings] = useState([]);
  const { currentUser } = useAuth(); // Use the current user from the AuthContext

  useEffect(() => {
    if (currentUser) {
    }
  }, [currentUser]); // Dependency on currentUser so it only runs when currentUser changes

  const [loggedScreenings, setLoggedScreenings] = useState(false); // New state variable
  const userId = currentUser ? currentUser.uid : null;

  useEffect(() => {
    async function fetchFilmDetails() {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/api/films/${filmId}`,
        );
        const data = await response.json();
        setFilm(data);
      } catch (error) {
        console.error("Error fetching film details:", error);
      }
    }
    fetchFilmDetails();
  }, [filmId]); // Only dependent on filmId

  useEffect(() => {
    async function fetchFilmScreenings() {
      try {
        const screeningsResponse = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/api/screenings`,
        );
        const allScreenings = await screeningsResponse.json();
        const filmScreenings = allScreenings.filter(
          (screening) => screening.film_id === Number(filmId),
        );

        const venuesResponse = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/api/venues`,
        );
        const venues = await venuesResponse.json();

        const screeningsWithVenues = filmScreenings.map((screening) => {
          const venue = venues.find((v) => v.venue_id === screening.venue_id);
          return {
            ...screening,
            venueName: venue ? venue.name : "Unknown Venue",
          };
        });

        // Sort screenings based on start_time
        const sortedFilmScreenings = screeningsWithVenues.sort(
          (a, b) => new Date(a.start_time) - new Date(b.start_time),
        );
        setScreenings(sortedFilmScreenings);
      } catch (error) {
        console.error("Error fetching film screenings:", error);
      }
    }
    fetchFilmScreenings();
  }, [filmId]);

  useEffect(() => {
    if (!loggedScreenings && screenings.length > 0) {
      screenings.forEach((screening) => {
        const originalTime = screening.start_time;
        const festivalTimezone = screening.timezone;
        const convertedTime = moment
          .utc(originalTime)
          .tz(festivalTimezone)
          .format("M/D/YYYY, h:mm A");
      });
      setLoggedScreenings(true); // Set to true after logging
    }
  }, [screenings, loggedScreenings]); // Only dependent on screenings and loggedScreenings

  if (!film) {
    return <p>Loading...</p>;
  }

  return (
    <FilmDetailsContainer>
      <FilmImage src={film.image} alt={film.title} />

      <Link to={`/festivals/${film.festival_id}`}>Back to Festival</Link>
      <FilmTitle>{film.title}</FilmTitle>
      {/* <p>{film.description}</p> */}
      <p>
        <strong>Director:</strong> {film.director}
      </p>
      <h2>Screenings</h2>
      <ScreeningsList>
        {screenings.map((screening) => (
          <li key={screening.screening_id}>
            {moment(screening.start_time)
              .tz(screening.timezone)
              .format("M/D/YYYY, h:mm A")}
            {" @ "}
            {screening.venueName}
          </li>
        ))}
      </ScreeningsList>
    </FilmDetailsContainer>
  );
}

export default FilmDetails;
