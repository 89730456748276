import styled from "styled-components";
import { Link } from "react-router-dom";
import { LocationOn, ContentCopy as ContentCopyIcon } from "@mui/icons-material";

export const UserScheduleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const UserScheduleInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end; // Align items to the bottom
  padding: 0 16px;
  margin-bottom: 2rem; // Adjust as needed

  @media (max-width: 768px) {
    flex-direction: column; // Stack items vertically on mobile
    align-items: start; // Align items to the start
    padding: 0 1rem; // Adjust padding for mobile
    margin: 1.5rem 0rem;
  }
`;

export const UserScheduleTitle = styled.h1`
  font-weight: 900;
  font-size: 2em; // Adjust size as needed
  margin: 0; // Remove default margins

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

export const ScheduledScreeningsInfo = styled.h2`
  font-weight: 700; // Normal font weight for the screenings info
  font-size: 1.5em; // Adjust size as needed
  margin: 0.25em 0 0 0;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

export const ScheduleDetails = styled.div`
  text-align: right; // Right-align the text

  @media (max-width: 768px) {
    text-align: left;
    font-size: 1rem;
    word-wrap: break-word; // Ensures text wraps to avoid overflow
    overflow: hidden; // Hides any overflow (alternative: use 'auto' for scroll)
    width: 100%;
  }
`;

export const ScheduleId = styled.p`
  font-size: 1.1em; // Adjust size as needed
  font-weight: 700;
  margin: 0; // Remove default margins

  @media (max-width: 768px) {
    font-size: 1rem;
    display: none;
  }
`;

export const LastUpdated = styled.p`
  font-size: 1.1em; // Adjust size as needed
  margin: 0; // Remove default margins
  margin-top: 10px;

  @media (max-width: 768px) {
    font-size: 1rem;
    margin-top: 15px;
  }
`;

export const ScheduleDay = styled.div`
  padding: 8px; // Replace with actual spacing token
  background: #e8e8e8;
  padding: 30px;
  margin-bottom: 4rem;
  border-radius: 30px;

  @media (max-width: 768px) {
    padding: 1rem;
    border-radius: 15px;
    margin-bottom: 2rem;
  }
`;

export const ScheduleDayInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center; // Align items to the center, as in TitleDateContainer
  margin: 0px 0px 20px 0px; // Match the margin from TitleDateContainer
  padding: 0; // Remove padding to match TitleDateContainer

  @media (max-width: 768px) {
    justify-content: center;
    margin: 0px 30px 16px 30px;
  }
`;

export const DateText = styled.h3`
  font-size: 1.5em; // Adjust to match the Title font size from TitleDateContainer
  font-weight: 900; // Match the font weight from TitleDateContainer
  margin: 0; // Keep margin at 0
  padding: 0; // Keep padding at 0
`;

export const DayOfWeek = styled.p`
  font-size: 1.5em; // Match the font size from Title in TitleDateContainer
  font-weight: 900; // Match the font weight from Title in TitleDateContainer
  margin: 0; // Adjust as needed
  padding: 0; // Adjust as needed
`;

export const FilmCount = styled.h3`
  margin: 0; // Keep margin at 0 to match DateRange
`;

export const ScheduleDayCalendar = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-image: linear-gradient(
    135deg,
    rgba(0, 0, 0, 0.1) 20%,
    transparent 20%,
    transparent 50%,
    rgba(0, 0, 0, 0.1) 50%,
    rgba(0, 0, 0, 0.1) 70%,
    transparent 70%,
    transparent
  );
  background-size: 8px 8px;
  background-color: #e6e6e6;

  @media (max-width: 768px) {
    // Adjust layout for mobile view
    // Example: reducing padding or changing background
  }
`;

export const ScheduleFilmListItem = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 30px 16px 16px;
  background: #f9f9f9;
  border-radius: 10px;

  @media (max-width: 768px) {
    padding: 20px 10px;
    border-radius: 0; // Reset border-radius for all items on mobile
    gap: 15px;

    &:first-of-type {
      border-radius: 10px 10px 0 0;
    }

    &:last-of-type {
      border-radius: 0 0 10px 10px;
    }

    &:only-of-type {
      border-radius: 10px;
    }
  }
`;

export const ScheduleFilmListItemColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;

  &:first-child {
    flex: 0 0 150px;
    margin-right: 30px;
    align-items: center;

    @media (max-width: 768px) {
      flex: 0 0 auto; // Adjust size for mobile
      margin-right: 0; // Reduce margin for mobile
    }
  }

  &:nth-child(2) {
    flex: 0 0 254px;

    @media (max-width: 768px) {
      display: none; // Hide the image container completely
    }
  }

  &:nth-child(3) {
    flex: 1; // Takes up remaining space

    @media (max-width: 768px) {
      flex: 1; // Adjust for mobile, ensuring it takes up all remaining space
    }
  }

  // You can add styles for the last child if it's being used
  /* &:last-child {
    @media (max-width: 768px) {
      // Mobile specific styles for the last child
    }
  } */
`;

export const ScheduleBufferTime = styled.div`
  display: flex;
  // justify-content: center;
  // align-items: center;
  margin: -15px;
  z-index: 0;

  @media (max-width: 768px) {
    z-index: 0;
    margin: 0;
  }
`;

export const BufferTimeInfo = styled.div`
  text-align: center;
  padding: 15px 20px;
  border-radius: 5px;
  background-color: ${({ bufferDuration }) => {
    if (bufferDuration <= 0) return "#ff00dd"; // Red for less than 45 minutes
    if (bufferDuration <= 45) return "#ef7272"; // Red for less than 45 minutes
    if (bufferDuration <= 75) return "#ffa726"; // Orange for less than 1 hour 15 minutes
    if (bufferDuration >= 76) return "#66bb6a"; // Green for 2 hours or more
    return "#efefef"; // Default color for other durations
  }};
  margin-left: 446px;
  z-index: 1000;
  font-weight: 700;

  @media (max-width: 768px) {
    margin-left: 0;
    width: 100%;
    border-radius: 0;
  }
`;

export const ScheduleFilmListItemTime = styled.div`
  font-size: 0.8em; // Replace with actual font size token
  font-weight: 700;

  @media (max-width: 768px) {
    font-size: 0.7rem;
  }
`;

export const ScheduleFilmListItemTitle = styled.h3`
  font-size: 1.5em; // Replace with actual font size token
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;

  @media (max-width: 768px) {
    font-size: 1.2rem;
    margin-bottom: 0;
  }
`;

export const ScheduleFilmListItemVenue = styled.p`
  font-size: 0.9em; // Replace with actual font size token
  margin-bottom: 1.5rem;
  margin-top: 0.25rem;
`;

export const StartTimeHeader = styled.h2`
  font-size: 1.8em;
  font-weight: 900;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 1.25rem;
  }
`;

export const EndTimeText = styled.p`
  font-size: 0.8em; // Smaller text for end time
  margin-top: 0.5em;
  margin-bottom: 0;
`;

export const FilmImage = styled.img`
  width: 224px;
  height: 137px;
  border-radius: 5px;
  object-fit: cover;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const DurationText = styled.p`
  font-size: 0.9em;
  margin: 0;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const VenueInfo = styled.div`
  display: flex;
  align-items: center;
  font-weight: 700;
  svg {
    margin-right: 0.5rem; // Space between icon and text
  }
`;

export const ScreeningNumber = styled.p`
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 1rem; // Spacing before the button
`;

export const SeeMoreButton = styled.a`
  text-decoration: none;
  color: #1a73e8; // Color for the button
  cursor: pointer;
`;

export const LocationIcon = styled(LocationOn)`
  color: inherit; // Use current color
  font-size: 18px !important;
  margin-right: 4px !important;
`;

export const UrlContainer = styled.div`
  background-color: #e8e8e8;
  color: #000;
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 0.9em;
  margin-bottom: 8px;
  width: 300px; // Set the width to 300px
  overflow-x: auto; // Allow horizontal scrolling
  white-space: nowrap; // Keep the content on a single line
  scrollbar-width: none; // Hide scrollbar for Firefox

  &::-webkit-scrollbar {
    display: none; // Hide scrollbar for Chrome, Safari, and Opera
  }
`;


export const IconButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border-radius: 4px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #e0e0e0; // Light grey on hover
  }
`;

export const CopyIcon = styled(ContentCopyIcon)`
  font-size: 1.2em; // Adjust the size of the icon as needed
`;

export const StyledButton = styled.button`
  display: inline-flex;
  width: 300px;
  align-items: center;
  font-size: 14px;
  justify-content: center;
  background-color: #000000;
  color: #ffffff;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  border-radius: 5px;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  transition:
    background-color 0.3s ease,
    color 0.3s ease,
    opacity 0.3s ease;

  &:hover {
    background-color: #e8e8e8;
    color: #000;
  }

  &:focus {
    outline: none;
  }

  &.outline {
    background-color: transparent;
    color: #000000;
    border: 2px solid #000000;

    &:hover {
      background-color: #000;
      color: #fff;
    }
  }
  
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 10px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: stretch; // This will make the buttons fill the container
  }
`;
