import styled from "styled-components";

export const FestivalCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 362px;
  padding: 10px;
  border-radius: 10px;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.3s ease; // Add a transition for smoothness
  position: relative;

  // Conditionally apply hover effect based on isUpcoming prop
  &:hover {
    box-shadow: ${(props) =>
      props.isUpcoming
        ? "0 2px 5px rgba(0, 0, 0, 0.2)"
        : "0px 4px 8px rgba(0, 0, 0, 0.3)"};
    // Include other hover styles here if necessary
  }
`;

export const FestivalImage = styled.img`
  display: block;
  width: 100%;
  height: 150px; // Adjust as per your design
  object-fit: cover;
  border-radius: 5px;
`;

export const FestivalInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline; // Align the text baselines across the container
  padding: 20px 10px 10px 10px;
  background: #fff;
  color: #333;
`;

export const FestivalDetailsLeft = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  align-items: flex-start;
`;

export const FestivalDetailsRight = styled.div`
  display: flex;
  margin: 0;
  flex-direction: column;
  align-items: flex-end;
`;

export const FestivalName = styled.h3`
  margin: 0;
  margin-bottom: 0.75rem;
  color: #000;
`;

export const FestivalLocation = styled.p`
  margin: 0;
  margin-bottom: 0.75rem;
  font-size: 0.9rem;
  color: #666;
`;

export const FestivalDate = styled.p`
  margin: 0;
  font-size: 0.8rem;
  color: #666;
`;

export const FestivalType = styled.span`
  display: inline-block;
  background: #005f73;
  color: #fff;
  margin: 0;
  padding: 2px 8px;
  border-radius: 5px;
  font-size: 0.8rem;
`;

export const FestivalStatusLabel = styled.span`
  background-color: #f0ad4e;
  color: #000;
  padding: 0.3rem 0.4rem;
  border-radius: 2px;
  font-size: 0.8em;
  position: absolute;
  top: 15px;
  left: 15px;
`;
