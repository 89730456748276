import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import {
  FestivalCard,
  FestivalImage,
  FestivalInfo,
  FestivalName,
  FestivalLocation,
  FestivalDate,
  FestivalType,
  FestivalDetailsLeft,
  FestivalDetailsRight,
  FestivalStatusLabel,
} from "./FestivalListItem.styled";

const FestivalListItem = ({ festival }) => {
  // Use moment-timezone to parse and format the dates in the festival's timezone
  const startDate = moment
    .tz(festival.start_date, festival.timezone)
    .format("MMMM D");
  const endDate = moment
    .tz(festival.end_date, festival.timezone)
    .format("D, YYYY");
  const isUpcoming = festival.status === "Upcoming";

  console.log(
    `Raw start date: ${festival.start_date}, Timezone: ${festival.timezone}`,
  );
  console.log(`Formatted start date: ${startDate}`);

  return (
    <FestivalCard isUpcoming={isUpcoming}>
      {isUpcoming && <FestivalStatusLabel>Coming Soon</FestivalStatusLabel>}
      <Link
        to={isUpcoming ? "#" : `/festivals/${festival.festival_id}`}
        style={{
          textDecoration: "none",
          pointerEvents: isUpcoming ? "none" : "auto",
        }}
      >
        <FestivalImage src={festival.image} alt={festival.name} />
        <FestivalInfo>
          <FestivalDetailsLeft>
            <FestivalName>{festival.name}</FestivalName>
            <FestivalDate>{`${startDate} - ${endDate}`}</FestivalDate>
          </FestivalDetailsLeft>
          <FestivalDetailsRight>
            <FestivalLocation>{festival.location}</FestivalLocation>
            <FestivalType>{festival.festival_type}</FestivalType>
          </FestivalDetailsRight>
        </FestivalInfo>
      </Link>
    </FestivalCard>
  );
};

export default FestivalListItem;
