import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import moment from "moment-timezone";

function ScreeningDetail() {
  const { screeningId } = useParams();
  const [screening, setScreening] = useState(null);
  const [film, setFilm] = useState(null);
  const [venue, setVenue] = useState(null);

  useEffect(() => {
    async function fetchScreeningDetail() {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/screenings/${screeningId}`);
        if (!response.ok) {
          throw new Error("Failed to fetch screening details");
        }
        const data = await response.json();
        setScreening(data);
        // Fetch film details
        const filmResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/films/${data.film_id}`);
        const filmData = await filmResponse.json();
        setFilm(filmData);
        // Fetch venue details
        const venueResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/venues/${data.venue_id}`);
        const venueData = await venueResponse.json();
        setVenue(venueData);
      } catch (error) {
        console.error("Error fetching screening details:", error);
      }
    }

    fetchScreeningDetail();
  }, [screeningId]);

  if (!screening || !film || !venue) return <div>Loading...</div>;

  const duration =
    new Date(screening.end_time) - new Date(screening.start_time);

  return (
    <div>
      <h1>Screening Detail</h1>
      <p>
        <strong>Film:</strong>{" "}
        <Link to={`/festivals/${film.festival_id}/films/${film.film_id}`}>
          {film.title}
        </Link>
      </p>
      <p>
        <strong>Venue:</strong> {venue.name}
      </p>
      <p>
        <strong>Start Time:</strong>{" "}
        {moment(screening.start_time)
          .tz(screening.timezone)
          .format("M/D/YYYY, h:mm A")}
      </p>
      <p>
        <strong>End Time:</strong>{" "}
        {moment(screening.end_time)
          .tz(screening.timezone)
          .format("M/D/YYYY, h:mm A")}
      </p>
      <p>
        <strong>Runtime:</strong> {Math.floor(duration / (1000 * 60))} minutes
      </p>
    </div>
  );
}

export default ScreeningDetail;
