import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import React from "react";
import { AuthProvider } from "./contexts/AuthContext";
import Home from "./components/home/Home";
import SignUp from "./components/navigation/SignUp";
import LogIn from "./components/navigation/LogIn";
import Nav from "./components/navigation/Nav";
import FestivalList from "./components/festival/FestivalList";
import FestivalDetail from "./components/festival/FestivalDetail";
import FilmDetails from "./components/film/FilmDetails";
import ScreeningDetail from "./components/screening/ScreeningDetail";
import PublicSchedule from "./components/user/PublicSchedule";
import Footer from "./components/navigation/footer";
import Feedback from "./components/navigation/Feedback";

function App() {
  return (
    <AuthProvider>
      <Router>
        <Nav />
        <div className="App">
          <Feedback />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<LogIn />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/festivals" element={<FestivalList />} />
            <Route path="/festivals/:festivalId" element={<FestivalDetail />} />
            <Route path="/schedule/:token" element={<PublicSchedule />} />
            <Route
              path="/festivals/:festivalId/schedule"
              element={<FestivalDetail />}
            />
            <Route
              path="/festivals/:festivalId/films/:filmId"
              element={<FilmDetails />}
            />
            <Route
              path="/festivals/:festivalId/films/:filmId/screenings/:screeningId"
              element={<ScreeningDetail />}
            />
          </Routes>
          <Footer />
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
