import styled from "styled-components";

export const PreferencesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f8f8f8;
  padding: 0rem 2rem;
  height: 75px;
  position: sticky;
  top: 75px;
  z-index: 975;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); // Optional: adds a subtle shadow to the navbar

  @media (max-width: 768px) {
    top: 155px;
  }
`;

export const StatsContainer = styled.div`
  font-weight: bold;
`;

export const CheckboxContainer = styled.div`
  //placeholder
`;
