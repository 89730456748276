import styled from "styled-components";

export const FestivalDetailContainer = styled.div`
  width: 90%;
  margin: 35px auto;
  /* margin-top: 35px; */
  display: flex;
  flex-direction: column;
  max-width: 1900px;
  min-height: 87vh;

  @media (max-width: 768px) {
    width: 100%;
    margin-top: 0%;
  }
`;

export const FilmListContainer = styled.div`
  padding: 30px;
  background-color: #e8e8e8;
  border-radius: 30px;

  @media (max-width: 768px) {
    border-radius: 0px;
    padding: 15px;
  }
`;

export const TitleDateContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 0px 20px 0px;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const Title = styled.h2`
  margin: 0; // Adjust as needed
  font-weight: 900;
`;

export const DateRange = styled.h3`
  margin: 0; // Adjust as needed
`;

export const StyledFilmList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

export const StyledLetterHeader = styled.h2`
  background-color: #000;
  color: #fff;
  font-weight: 900;
  border-radius: 5px;
  padding: 5px 16px;
  margin-bottom: 10px;
  margin-top: 30px;
  scroll-margin-top: 80px;

  @media (max-width: 768px) {
    margin-top: 15px;
  }
`;

export const LetterHeaderScrollContainer = styled.div`
  scroll-margin-top: 85px;

  @media (max-width: 768px) {
    scroll-margin-top: 165px;
  }
`;
