import React from "react";
import PropTypes from "prop-types"; // Import PropTypes for type checking

function FestivalStats({ maybeCount, definitelyCount }) {
  return (
    <div>
      <span className="stat">{definitelyCount + maybeCount} Films in Your Shortlist</span>
      <span className="separator"> | </span>
      <span className="stat">Maybe: {maybeCount}</span>
      <span className="separator"> | </span>
      <span className="stat">Definitely: {definitelyCount}</span>
    </div>
  );
}

FestivalStats.propTypes = {
  maybeCount: PropTypes.number.isRequired,
  definitelyCount: PropTypes.number.isRequired,
};

export default FestivalStats;
