import styled from "styled-components";

export const SchedulePreferencesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #000;
  color: #fff;
  position: sticky;
  top: 75px;
  z-index: 975;
  padding: 0rem 2rem;
  height: 75px;

  @media (max-width: 768px) {
    overflow-x: auto; // Enable horizontal scrolling
    white-space: nowrap; // Prevents wrapping of child elements
    padding: 0rem 1rem; // Adjust padding for smaller screens
    -webkit-overflow-scrolling: touch; // Smooth scrolling on touch devices
    gap: 15px;
    top: 155px;
  }
`;

export const TitleContainer = styled.div`
  //placeholder

  @media (max-width: 768px) {
    display: none;
  }
`;

export const ControlsContainer = styled.div`
  display: flex;
  align-items: center;

  label,
  select,
  button {
    margin-left: 1rem;
    color: #fff;
  }

  button {
    background-color: #fff;
    color: #000;
    border: none;
    padding: 0.5rem 1rem;
    cursor: pointer;
    border-radius: 4px;

    &:hover {
      background-color: #e8e8e8;
      color: #000;
    }
  }

  select {
    background-color: #333;
    color: #fff;
    border: 1px solid #444;
  }

  input[type="checkbox"] {
    accent-color: #fff;
  }

  @media (max-width: 768px) {
    gap: 15px;

    label,
    select {
      margin-left: 0;
    }
  }
`;
