import React from "react";
import { NavContainer, LetterButton } from "./AlphabetNav.styled";

const AlphabetNav = ({ groupedFilms, visibleSections }) => {
  const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
  const scrollToSection = (letter) => {
    const section = document.getElementById(letter);
    const offset = 80; // Height of your sticky navigation bar in pixels

    if (section) {
      section.scrollIntoView({ behavior: "smooth", block: "start" });

      // Adjust the scroll position to account for the navigation bar height
      const scrolledY = window.scrollY;

      if (scrolledY) {
        window.scroll(0, scrolledY - offset);
      }
    }
  };

  return (
    <NavContainer className="alphabet-nav">
      <LetterButton
        onClick={() => scrollToSection("#")}
        disabled={!visibleSections["#"]}
      >
        #
      </LetterButton>
      {alphabet.map((letter) => (
        <LetterButton
          key={letter}
          onClick={() => scrollToSection(letter)}
          disabled={!visibleSections[letter]}
        >
          {letter}
        </LetterButton>
      ))}
    </NavContainer>
  );
};

export default AlphabetNav;
