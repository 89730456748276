import styled from "styled-components";
import { Link } from "react-router-dom";

export const StyledContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 35px;
  height: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 1rem;
  }
`;

export const HeroSection = styled.section`
  display: flex;
  align-items: center;
  justify-content: center; // Center the nested container
  height: 80vh;
  width: 100%; // Full width for background
  background-color: rgb(142, 197, 252);
  background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      white 100%
    ),
    linear-gradient(61deg, #f3f8fe 0%, #ead4ff 100%);
  padding: 45px 0 0 0;

  @media (max-width: 768px) {
    padding: 0;
    height: auto;
    background-color: rgb(142, 197, 252);
    background-image: linear-gradient(
        to top,
        rgba(255, 255, 255, 0) 40%,
        white 100%
      ),
      linear-gradient(61deg, #f3f8fe 0%, #ead4ff 100%);
  }
`;

export const HeroContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  max-width: 1200px; // Restrict content width
  margin: 0 auto;
  padding: 0 35px;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 0;
  }
`;

export const HeroText = styled.div`
  flex: 1;
  padding-right: 100px;

  h1 {
    font-size: 3.5rem;
    font-weight: 900;
    color: #000;
    margin-bottom: 1rem;
    margin-top: 0;
  }

  p {
    font-size: 1.25rem;
    font-weight: 400;
    color: #000;
    margin-bottom: 1.25rem;
    line-height: 175%;
  }

  @media (max-width: 768px) {
    padding: 1rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
      font-size: 2.5rem; // Smaller font size
      margin-top: 0.5rem;
      margin-bottom: 0; // Reduced margin
    }

    p {
      font-size: 1rem; // Smaller font size
      padding: 0rem 2.5rem;
    }
  }
`;

export const HeroImageContainer = styled.div`
  flex: 1;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  height: 100%;

  @media (max-width: 768px) {
    margin-top: 1rem;
    height: auto; // Reset to auto on mobile devices
    border-radius: 0;
  }
`;

export const HeroImage = styled.img`
  width: 100%;
  object-fit: cover;
`;

export const TestimonialSection = styled.section`
  background-color: #ffffff;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 6rem 0;

  @media (max-width: 768px) {
    padding: 3rem 0;
  }
`;

export const TestimonialBlockquote = styled.blockquote`
  color: #000;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 2rem;
  font-style: normal;
  font-weight: 900;
  padding: 0 30%;
  margin: 0 auto;
  line-height: 150%;

  @media (max-width: 768px) {
    padding: 0 15%;
    font-size: 25px;
  }
`;

export const TestimonialImage = styled.img`
  width: 75px;
  height: 75px;
  border-radius: 50%;
  margin: 1rem;
`;

export const TestimonialParagraph = styled.p`
  color: #000;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin: 0;
`;

export const FeatureRow = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap; // Prevent wrapping to keep items in a single row
  margin: 0;
  flex-direction: column;
`;

export const FeatureHeader = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 30px;
  margin-top: 30px;

  p {
    font-size: 1.25rem;
    line-height: 175%;
    font-weight: 300;
    padding: 0px 7rem;
  }

  @media (max-width: 768px) {
    margin-bottom: 20px;

    p {
      padding: 0px 1.5rem;
    }
  }
`;

export const FeatureText = styled.p`
  font-size: 1.2rem;
  line-height: 135%;
  font-weight: 300;

  @media (max-width: 768px) {
    font-size: 1rem;
    line-height: 175%;
    /* padding: 0 2.8rem; */
  }
`;

export const FeatureScrollContainer = styled.div`
  display: flex;
  gap: 30px;

  @media (max-width: 768px) {
    overflow-x: auto; // Enable horizontal scrolling only for mobile devices
    -webkit-overflow-scrolling: touch; // Smooth scrolling on iOS devices
    margin: 0 -1rem; // Adjust margin to align with the StyledContainer padding
    display: flex;
    padding: 0rem 2rem;
  }
`;

export const FeatureCol = styled.div`
  padding: 0;
  flex: 0 0 auto; // Prevent flex items from shrinking but allow them to grow
  width: calc(33% - 16px);

  @media (max-width: 768px) {
    /* padding: 0 15px; */
    width: 70%; // Slightly larger width on mobile to show part of the next item
  }
`;

export const FeatureImageWrapper = styled.div`
  width: 100%;
  padding-top: 100%;
  position: relative;
  overflow: hidden;
  margin-bottom: 20px;
  border-radius: 5px;
`;

export const FeatureImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
`;

export const CTASection = styled.section`
  background-color: #fff;
  display: flex;
  justify-content: center; // Center the inner container
  margin: 60px 0 0 0;
  padding: 30px 0;
  text-align: left;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: stretch; // Stretch to full width on smaller screens
    margin: 0;
  }
`;

export const CTAContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px; // Set the max width for the content
  padding: 0 35px; // Optional: padding for small screens

  @media (max-width: 768px) {
    flex-direction: column;
    width: auto;
  }
`;

export const CTAContent = styled.div`
  h2 {
    margin: 0;
    margin-bottom: 1rem;
  }

  p {
    margin: 0;
    margin-top: 1rem;
  }

  @media (max-width: 768px) {
    margin-bottom: 30px;
    text-align: center; // Center-align the text
    h2 {
      margin: 0;
      margin-bottom: 0.5rem;
    }

    p {
      margin: 0;
      margin-top: 0.5rem;
    }
  }
`;

export const CTAButtons = styled.div`
  display: flex;
  gap: 15px;

  @media (max-width: 768px) {
    width: 100%;
    justify-content: center; // Center buttons
    flex-wrap: wrap; // Wrap buttons if needed
  }
`;

export const StyledButton = styled(Link)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #000000;
  color: #ffffff;
  border: none;
  padding: 1rem 3rem;
  cursor: pointer;
  border-radius: 5px;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  transition:
    background-color 0.3s ease,
    color 0.3s ease;
  width: 110px;

  &:hover {
    background-color: #e8e8e8;
    color: #000;
  }

  &:focus {
    outline: none;
  }

  &.outline {
    background-color: #ffffff;
    color: #000000;
    border: 2px solid #000000;

    &:hover {
      background-color: #000;
      color: #fff;
    }
  }
`;

export const HeroCTAButton = styled(Link)`
  display: inline-flex; // Ensures proper alignment as a link
  align-items: center; // Centers content vertically
  justify-content: center; // Centers content horizontally
  background-color: #000000;
  color: #ffffff;
  border: none;
  padding: 1.25rem 2rem;
  cursor: pointer;
  border-radius: 5px;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1rem;
  text-decoration: none; // Removes default link styling
  transition:
    background-color 0.3s ease,
    color 0.3s ease;

  &:hover {
    background-color: #c5ddfd;
    color: #000;
  }

  &:focus {
    outline: none;
  }

  @media (max-width: 768px) {
    width: 80%;
    padding: 0.75rem;
  }
`;

export const Footer = styled.footer`
  border-top: 1px solid #e0e0e0;
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`;

export const FooterText = styled.p`
  margin: 0;
  padding: 0 20px;
  color: #666;
  font-size: 0.9rem;
`;
