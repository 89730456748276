import React from "react";
import PropTypes from "prop-types";
import SchedulePreferences from "./SchedulePreferences";

const ScheduleContainer = ({
  userId,
  festivalId,
  preferences,
  setPreferences,
}) => {
  return (
    <SchedulePreferences
      userId={userId}
      festivalId={festivalId}
      preferences={preferences}
      setPreferences={setPreferences}
    />
  );
};

ScheduleContainer.propTypes = {
  userId: PropTypes.string.isRequired,
  festivalId: PropTypes.number.isRequired,
  preferences: PropTypes.object.isRequired,
  setPreferences: PropTypes.func.isRequired,
};

export default ScheduleContainer;
