import { React, useRef } from "react";
import { Link } from "react-router-dom";
import {
  FilmListItemContainer,
  PriorityAndImageContainer,
  FilmDetailsContainer,
  ScreeningsContainer,
  FilmImage,
  FilmTitle,
  FilmParagraph,
  ScreeningsParagraph,
  ViewMoreButton,
  UserFilmsContainer,
  ButtonText,
  StyledOpenInNewIcon,
} from "./FilmListItem.styled";
import PropTypes from "prop-types";
import UserFilms from "../user/UserFilms";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const FilmListItem = ({ film, userId, isOdd, onPriorityChange, priority }) => {
  const {
    film_id: filmId,
    title,
    director,
    genres = [],
    image,
    screenings = [],
    external_url: externalUrl,
  } = film;

  const linkRef = useRef(null);

  const addTransition = () => {
    linkRef.current.style.transition = "filter 150ms linear";
  };

  const removeTransition = () => {
    linkRef.current.style.transition = "none";
  };

  return (
    <FilmListItemContainer isOdd={isOdd}>
      <PriorityAndImageContainer>
        <FilmImage src={image} alt={title} />
      </PriorityAndImageContainer>

      <FilmDetailsContainer>
        <FilmTitle>{title}</FilmTitle>
        <FilmParagraph>Director: {director}</FilmParagraph>
        <FilmParagraph>
          Genre: {genres.map((genre) => genre.genre_name).join(", ")}
        </FilmParagraph>
      </FilmDetailsContainer>

      <UserFilmsContainer>
        <UserFilms
          filmId={Number(filmId)}
          userId={userId}
          title={title}
          priority={priority}
          onPriorityChange={onPriorityChange}
        />
      </UserFilmsContainer>

      <ScreeningsContainer>
        <ScreeningsParagraph>
          {screenings.length} Screenings
        </ScreeningsParagraph>
        <ViewMoreButton
          as={externalUrl ? "a" : Link}
          href={externalUrl || undefined}
          target={externalUrl ? "_blank" : undefined}
          rel={externalUrl ? "noopener noreferrer" : undefined}
          to={
            !externalUrl
              ? `/festivals/${film.festival_id}/films/${film.film_id}`
              : undefined
          }
          hasExternalUrl={!!externalUrl}
        >
          View More
          {externalUrl && <StyledOpenInNewIcon fontSize="small" />}
        </ViewMoreButton>
      </ScreeningsContainer>
    </FilmListItemContainer>
  );
};

FilmListItem.propTypes = {
  film: PropTypes.shape({
    film_id: PropTypes.number,
    title: PropTypes.string,
    director: PropTypes.string,
    genres: PropTypes.array,
    image: PropTypes.string,
    screenings: PropTypes.array,
    festival_id: PropTypes.number,
    external_url: PropTypes.string,
  }),
  userId: PropTypes.string.isRequired,
  isOdd: PropTypes.bool.isRequired,
  onPriorityChange: PropTypes.func.isRequired,
  priority: PropTypes.number,
};

export default FilmListItem;
