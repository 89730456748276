import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from 'styled-components';
import { theme, GlobalStyle } from './theme'; // Import theme and GlobalStyle

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <GlobalStyle /> {/* Injects global styles */}
      <App />
    </ThemeProvider>
  </React.StrictMode>
);

reportWebVitals();
