import styled from 'styled-components';

export const FeedbackButton = styled.a`
  position: fixed;
  bottom: 15px;
  right: 15px;
  background-color: black;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1000; // Ensure it's above most elements

  &:hover {
    background-color: #333; // Slightly lighter on hover for feedback
  }
`;
