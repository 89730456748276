import styled from "styled-components";
import { Link } from "react-router-dom";

export const LoginPageContainer = styled.div`
  display: flex;
  height: 100vh;
`;

export const ImageContainer = styled.div`
  flex: 1;
  max-width: 500px;
  background-image: url("https://images.unsplash.com/photo-1517604931442-7e0c8ed2963c?w=1400&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTF8fG1vdmllc3xlbnwwfHwwfHx8MA%3D%3D");
  background-size: cover;
  background-position: center;

  @media (max-width: 768px) {
    display: none; // Hide the image container on smaller screens
  }
`;

export const LogInSection = styled.div`
  flex: 1;
  max-width: 400px;
  margin: auto; // Vertically center in the flex container
  margin-left: 100px; // Keep the margin-left of 100px
  padding: 1rem;

  @media (max-width: 768px) {
    margin: auto; // Reset margin-left on smaller screens
  }
`;

export const HeaderText = styled.h1`
  margin-bottom: 2rem;
`;

export const Input = styled.input`
  width: 100%;
  margin-bottom: 1rem;
  padding: 0.5rem;
  height: 50px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid #ccc;
`;

export const SubmitButton = styled.button`
  width: 100%;
  padding: 1rem;
  margin-bottom: 1rem;
  border: none;
  border-radius: 5px;
  background-color: #000;
  color: #fff;
  text-align: center;
  font-family: ${(props) => props.theme.fonts.primary};
  font-size: 1.125rem;
  font-weight: 400;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    background-color: #666666;
    color: #fff;
  }
`;

export const SignUpText = styled.p`
  text-align: center;
`;

export const SignUpLink = styled(Link)`
  color: #007bff;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;
