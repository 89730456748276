import React, { useState, useEffect } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import SearchIcon from "@mui/icons-material/Search";
import TuneIcon from "@mui/icons-material/Tune";
import SettingsIcon from "@mui/icons-material/Settings";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import {
  NavContainer,
  FestivalName,
  NavControls,
  SearchIconContainer,
  SearchInput,
  IconContainer,
  GenerateScheduleButton,
  FilmListButton,
  ScheduleButton,
  ButtonGroupContainer,
} from "./FestivalNavigation.styled";

const FestivalNavigation = ({
  festivalName,
  searchTerm,
  setSearchTerm,
  togglePreferences,
  toggleUnavailability,
  toggleSettings,
  onGenerateSchedule,
  sections,
  activeSection,
  userId,
  festivalId,
  onViewUserSchedule,
  onToggleView,
  isViewingUserSchedule,
}) => {
  const [activeIcon, setActiveIcon] = React.useState(null);

  const [preferences, setPreferences] = useState(null);
  const [scheduleGenerated, setScheduleGenerated] = useState(false);
  const [schedule, setSchedule] = useState(null);
  const [viewingSchedule, setViewingSchedule] = useState(false);

  useEffect(() => {
    if (userId) {
      if (process.env.NODE_ENV === "development") {
        console.log(
          "Checking if schedule exists for userId:",
          userId,
          "and festivalId:",
          festivalId,
        );
      }

      const checkScheduleExists = async () => {
        try {
          if (process.env.NODE_ENV === "development") {
            console.log("Sending API request to check schedule");
          }
          const response = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/api/schedule/latest/${userId}/${festivalId}`,
          );
          if (process.env.NODE_ENV === "development") {
            console.log("API response for schedule check:", response.data);
          }

          // Update the condition to check for schedule_data
          if (
            response.data &&
            response.data.schedule_data &&
            Object.keys(response.data.schedule_data).length > 0
          ) {
            setScheduleGenerated(true);
          } else {
            setScheduleGenerated(false);
          }
        } catch (error) {
          console.error("Error checking schedule:", error);
        }
      };

      checkScheduleExists();
    } else {
      if (process.env.NODE_ENV === "development") {
        console.log("userId is null, skipping schedule check.");
      }
    }
  }, [userId, festivalId]);

  const handleViewSchedule = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/schedule/latest/${userId}/${festivalId}`,
      );
      setSchedule(response.data.schedule);
      setViewingSchedule(true); // Toggle viewing the schedule
    } catch (error) {
      console.error("Error retrieving schedule:", error);
    }
  };

  const handleViewFilms = () => {
    onToggleView(false);
  };

  const isUserLoggedIn = !!userId;

  return (
    <NavContainer>
      <ButtonGroupContainer>
        <FestivalName>{festivalName}</FestivalName>
        <FilmListButton
          isSelected={!isViewingUserSchedule}
          onClick={handleViewFilms}
        >
          Full Film List
        </FilmListButton>
        <ScheduleButton
          isSelected={isViewingUserSchedule}
          scheduleGenerated={scheduleGenerated}
          onClick={scheduleGenerated ? onViewUserSchedule : undefined}
          disabled={!scheduleGenerated || !isUserLoggedIn}
        >
          Your Schedule
        </ScheduleButton>
      </ButtonGroupContainer>
      <NavControls>
        <SearchIconContainer>
          <SearchIcon />
          <SearchInput
            type="text"
            placeholder="Search Films..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </SearchIconContainer>
        <IconContainer
          onClick={
            isUserLoggedIn
              ? () => togglePreferences(sections.PREFERENCES)
              : undefined
          }
          className={activeSection === sections.PREFERENCES ? "active" : ""}
          disabled={!isUserLoggedIn}
        >
          <TuneIcon />
        </IconContainer>
        <IconContainer
          onClick={
            isUserLoggedIn ? () => toggleSettings(sections.SETTINGS) : undefined
          }
          className={activeSection === sections.SETTINGS ? "active" : ""}
          disabled={!isUserLoggedIn}
        >
          <SettingsIcon />
        </IconContainer>
        <IconContainer
          onClick={
            isUserLoggedIn
              ? () => toggleUnavailability(sections.UNAVAILABILITY)
              : undefined
          }
          className={activeSection === sections.UNAVAILABILITY ? "active" : ""}
          disabled={!isUserLoggedIn}
        >
          <EventBusyIcon />
        </IconContainer>
        <GenerateScheduleButton
          onClick={isUserLoggedIn ? onGenerateSchedule : undefined}
          disabled={!isUserLoggedIn}
        >
          Generate Schedule
        </GenerateScheduleButton>
      </NavControls>
    </NavContainer>
  );
};

FestivalNavigation.propTypes = {
  festivalName: PropTypes.string.isRequired,
  searchTerm: PropTypes.string.isRequired,
  setSearchTerm: PropTypes.func.isRequired,
  togglePreferences: PropTypes.func.isRequired,
  toggleUnavailability: PropTypes.func.isRequired,
  toggleSettings: PropTypes.func.isRequired,
  onGenerateSchedule: PropTypes.func.isRequired,
  onViewUserSchedule: PropTypes.func.isRequired,
  onToggleView: PropTypes.func.isRequired,
};

export default FestivalNavigation;
