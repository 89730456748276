import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import FilmListItem from "../film/FilmListItem";
import { useAuth } from "../../contexts/AuthContext";
import ScheduleContainer from "../schedule/ScheduleContainer";
import UserSchedule from "../user/UserSchedule";
import FestivalNavigation from "./FestivalNavigation";
import FestivalPreferences from "./FestivalPreferences";
import FestivalStats from "./FestivalStats";
import FestivalUnavailability from "./FestivalUnavailability";
import Loader from "../common/Loader";
import AlphabetNav from "../navigation/AlphabetNav";
import axios from "axios";
import moment from "moment-timezone";

import {
  FestivalDetailContainer,
  FilmListContainer,
  TitleDateContainer,
  Title,
  DateRange,
  StyledFilmList,
  StyledLetterHeader,
  LetterHeaderScrollContainer,
} from "./FestivalDetail.styled";

const Sections = {
  NONE: "NONE",
  PREFERENCES: "PREFERENCES",
  SETTINGS: "SETTINGS",
  UNAVAILABILITY: "UNAVAILABILITY",
};

// Utility function to remove 'The ' from the start of a string and return the rest
const ignoreThePrefix = (title) => title.replace(/^The\s+/i, "");

const sortFilmsByTitleIgnoringThe = (a, b) => {
  // Normalize titles
  const normalizeTitle = (title) => ignoreThePrefix(title).trim().toLowerCase();

  const titleA = normalizeTitle(a.title);
  const titleB = normalizeTitle(b.title);

  // Handle numeric titles
  const numA = parseFloat(titleA);
  const numB = parseFloat(titleB);

  if (!isNaN(numA) && !isNaN(numB)) {
    return numA - numB; // Compare as numbers
  }

  // Handle alphanumeric sorting
  return titleA.localeCompare(titleB);
};

const getFirstLetter = (title) => {
  const cleanedTitle = ignoreThePrefix(title).trim();
  const firstChar = cleanedTitle[0].toUpperCase();
  // If the first character is not a letter, return '#'
  return firstChar.match(/[A-Z]/i) ? firstChar : "#";
};

// Grouping the films by the first letter of their title
const groupFilmsByFirstLetter = (films) => {
  const groupedFilms = films.reduce((acc, film) => {
    const firstLetter = getFirstLetter(film.title) || "#";
    if (!acc[firstLetter]) {
      acc[firstLetter] = [];
    }
    acc[firstLetter].push(film);
    return acc;
  }, {});

  return groupedFilms;
};

function FestivalDetail() {
  const { festivalId } = useParams();
  const { userId } = useAuth();
  const [festival, setFestival] = useState(null);
  const [films, setFilms] = useState([]);
  const { currentUser } = useAuth();
  const [showSchedule, setShowSchedule] = useState(false);
  const [displayCount, setDisplayCount] = useState(300); // Initial count
  const [showZeroScreenings, setShowZeroScreenings] = useState(true);
  const [searchTerm, setSearchTerm] = useState(""); // New state variable
  const [selectedGenres, setSelectedGenres] = useState(new Set()); // New state variable
  const [allGenres, setAllGenres] = useState([]); // State variable to hold all genres
  const [filmPriorities, setFilmPriorities] = useState({}); // Initialize to an empty object
  const [showUnavailability, setShowUnavailability] = useState(false);
  const [festivalTimezone, setFestivalTimezone] = useState(null);
  const [userSchedule, setUserSchedule] = useState(null);
  const [isViewingUserSchedule, setIsViewingUserSchedule] = useState(false);
  const [loadingSchedule, setLoadingSchedule] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [scheduleHash, setScheduleHash] = useState(null);
  const [lastUpdated, setLastUpdated] = useState(null);
  const [visibleSections, setVisibleSections] = useState({});
  const [scheduleToken, setScheduleToken] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();
  const groupedFilms = groupFilmsByFirstLetter(films);
  useEffect(() => {
    // console.log(groupedFilms);
  }, [groupedFilms]);

  const [schedulePreferences, setSchedulePreferences] = useState({
    bufferTime: 60, // Default value
    includeUnprioritized: false,
    applyPopularityBuffer: false,
  });

  const handleToggleView = (viewingUserSchedule) => {
    setIsViewingUserSchedule(viewingUserSchedule);

    const newUrl = viewingUserSchedule
      ? `/festivals/${festivalId}/schedule`
      : `/festivals/${festivalId}`;
    navigate(newUrl);
  };

  useEffect(() => {
    const newUrl = isViewingUserSchedule
      ? `/festivals/${festivalId}/schedule`
      : `/festivals/${festivalId}`;
    navigate(newUrl);
  }, [isViewingUserSchedule, festivalId, navigate]);

  useEffect(() => {
    const pathSegments = location.pathname.split("/");
    const lastSegment = pathSegments[pathSegments.length - 1];

    // Toggle the view based on the URL segment
    setIsViewingUserSchedule(lastSegment === "schedule");
  }, [location]);

  // Function to fetch the user's schedule
  const handleViewUserSchedule = async () => {
    if (!userId) {
      console.error("UserId is not defined");
      return;
    }

    setLoadingSchedule(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/schedule/latest/${userId}/${festivalId}`,
      );
      const { schedule_data, schedule_hash, modified_at, scheduleToken } = response.data;      
      if (schedule_data) {
        setUserSchedule(schedule_data); // Pass the entire schedule_data object
        setScheduleHash(schedule_hash);
        setLastUpdated(modified_at);
        setScheduleToken(scheduleToken);
        setIsViewingUserSchedule(true);
      } else {
        if (process.env.NODE_ENV === "development") {
          console.log("No schedule found for this user and festival.");
        }
        setIsViewingUserSchedule(false);
      }
      setLoadingSchedule(false);
    } catch (error) {
      console.error("Error retrieving schedule:", error);
      setLoadingSchedule(false);
    }
  };

  // useEffect to show Visible Sections
  useEffect(() => {
    const newVisibleSections = {};

    Object.entries(groupedFilms).forEach(([letter, filmsInSection]) => {
      const filteredFilms = filmsInSection
        .filter((film) =>
          showZeroScreenings ? film.screenings.length > 0 : true,
        )
        .filter((film) =>
          film.title.toLowerCase().includes(searchTerm.toLowerCase()),
        )
        .filter(
          (film) =>
            selectedGenres.size === 0 ||
            film.genres.some((g) => selectedGenres.has(g.genre_id)),
        );

      newVisibleSections[letter] = filteredFilms.length > 0;
    });

    // Ensure the '#' section is accounted for correctly
    if (groupedFilms["#"] && groupedFilms["#"].length > 0) {
      newVisibleSections["#"] = true;
    }

    setVisibleSections(newVisibleSections);
  }, [films, showZeroScreenings, searchTerm, selectedGenres]);

  useEffect(() => {
    const fetchFestivalDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/festivals/${festivalId}`,
        );
        if (response.data && response.data.timezone) {
          setFestivalTimezone(response.data.timezone);
        }
      } catch (error) {
        console.error("Error fetching festival details:", error);
      }
    };

    fetchFestivalDetails();
  }, [festivalId]); // Assuming `festivalId` is either a prop or state in this component

  useEffect(() => {
    async function fetchData() {
      try {
        const [festivalResponse, filmsResponse] = await Promise.all([
          fetch(
            `${process.env.REACT_APP_API_BASE_URL}/api/festivals/${festivalId}`,
          ),
          fetch(
            `${process.env.REACT_APP_API_BASE_URL}/api/films/festival/${festivalId}`,
          ),
        ]);

        const festivalData = await festivalResponse.json();
        const filmsData = await filmsResponse.json();

        // Optionally, perform any transformations on filmsData here
        // for example, sorting them based on certain criteria

        setFestival(festivalData);
        setFilms(filmsData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, [festivalId]);

  useEffect(() => {
    const fetchPriorities = async () => {
      if (userId && festivalId) {
        try {
          const token = await currentUser.getIdToken(); // Fetch the token
          const response = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/api/userfilms/priorities/${userId}/${festivalId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`, // Include the token in the request headers
              },
            },
          );
          if (response.data) {
            setFilmPriorities(response.data);
          }
        } catch (error) {
          console.error("Error fetching film priorities:", error);
        }
      }
    };

    fetchPriorities();
  }, [userId, festivalId, currentUser]); // Add currentUser to the dependency array

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop ===
        document.documentElement.offsetHeight
      ) {
        setDisplayCount((prevCount) => prevCount + 20); // Load 20 more items
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // useEffect(() => {
  //   async function fetchGenres() {
  //     try {
  //       const response = await fetch("${process.env.REACT_APP_API_BASE_URL}/api/genres");
  //       const genres = await response.json();
  //       setAllGenres(genres);
  //     } catch (error) {
  //       console.error("Error fetching genres:", error);
  //     }
  //   }
  //   fetchGenres();
  // }, []);

  const clearPriorities = async () => {
    try {
      // Get the authentication token from the currentUser object
      const token = await currentUser.getIdToken();

      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/userfilms/clear-priorities`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Include the authentication token here
          },
        },
      );
      const data = await response.json();
      if (response.ok) {
        if (process.env.NODE_ENV === "development") {
          console.log(data.message);
        }
        // Update the UI here if necessary
      } else {
        console.error("Error clearing priorities:", data.error);
      }
    } catch (error) {
      console.error("Error clearing priorities:", error);
    }
  };

  const handleGenerateSchedule = async () => {
    setIsLoading(true);
    try {
      // Retrieve the token from the currentUser object
      const token = await currentUser.getIdToken();

      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/schedule/generate`,
        {
          userId,
          festivalId,
          preferences: schedulePreferences,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the request headers
          },
        },
      );
      const scheduleHash = response.data.scheduleHash;
      if (process.env.NODE_ENV === "development") {
        console.log("Schedule generated with hash:", scheduleHash);
      }
      // After generating the schedule, immediately fetch the new schedule
      await handleViewUserSchedule(); // Fetch the new schedule
      setIsViewingUserSchedule(true);
      setIsLoading(false); // Set loading to false on success
    } catch (error) {
      console.error("Error generating schedule:", error);
      setIsLoading(false); // Ensure loading is false on error
    }
  };

  const toggleShowZeroScreenings = () => {
    setShowZeroScreenings(!showZeroScreenings);
  };

  const handlePriorityChange = (filmId, newPriority) => {
    setFilmPriorities((prevPriorities) => ({
      ...prevPriorities,
      [filmId]: newPriority,
    }));
  };

  const maybeCount = Object.values(filmPriorities).filter(
    (p) => p === 1,
  ).length;
  const definitelyCount = Object.values(filmPriorities).filter(
    (p) => p === 2,
  ).length;

  const formatDateRange = (startDate, endDate, timezone) => {
    // Log the raw input values
    console.log(
      `Raw start date: ${startDate}, Raw end date: ${endDate}, Timezone: ${timezone}`,
    );

    // Parse the start and end dates in the specified timezone
    const start = moment.tz(startDate, timezone);
    const end = moment.tz(endDate, timezone);

    // Format options
    const startOptions = { month: "long", day: "numeric" };
    const endOptions =
      start.year() === end.year()
        ? { day: "numeric" }
        : { year: "numeric", month: "long", day: "numeric" };

    // Format the dates according to the options
    const startFormat = start.format("MMMM D");
    const endFormat = end.format(endOptions.day ? "D" : "MMMM D, YYYY");

    // Combine the formatted dates
    let formattedRange;
    if (start.month() === end.month() && start.year() === end.year()) {
      formattedRange = `${startFormat} - ${end.format("D, YYYY")}`;
    } else if (start.year() === end.year()) {
      formattedRange = `${startFormat} - ${end.format("MMMM D, YYYY")}`;
    } else {
      formattedRange = `${startFormat}, ${start.year()} - ${endFormat}`;
    }

    // Log the final formatted date range
    console.log(`Formatted date range: ${formattedRange}`);

    return formattedRange;
  };

  const [activeSection, setActiveSection] = useState(Sections.NONE);

  const togglePreferences = () => {
    setActiveSection((prev) =>
      prev === Sections.PREFERENCES ? Sections.NONE : Sections.PREFERENCES,
    );
  };

  const toggleSettings = () => {
    setActiveSection((prev) =>
      prev === Sections.SETTINGS ? Sections.NONE : Sections.SETTINGS,
    );
  };

  const toggleUnavailability = () => {
    setActiveSection((prev) =>
      prev === Sections.UNAVAILABILITY
        ? Sections.NONE
        : Sections.UNAVAILABILITY,
    );
  };

  return (
    <div>
      {isLoading ? (
        <Loader loading={isLoading} />
      ) : (
        <>
          <FestivalNavigation
            festivalName={festival?.name}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            togglePreferences={togglePreferences}
            toggleUnavailability={toggleUnavailability}
            toggleSettings={toggleSettings}
            sections={Sections}
            activeSection={activeSection}
            userId={userId}
            festivalId={festivalId}
            onViewUserSchedule={handleViewUserSchedule}
            onToggleView={handleToggleView}
            onGenerateSchedule={handleGenerateSchedule}
            isViewingUserSchedule={isViewingUserSchedule}
          />
          {/* {userId && (
      <button onClick={clearPriorities}>Clear All Priorities</button>
    )} */}
          {activeSection === Sections.SETTINGS && (
            <ScheduleContainer
              userId={userId}
              festivalId={festivalId}
              preferences={schedulePreferences}
              setPreferences={setSchedulePreferences}
            />
          )}
          {activeSection === Sections.PREFERENCES && (
            <FestivalPreferences
              showZeroScreenings={showZeroScreenings}
              toggleShowZeroScreenings={toggleShowZeroScreenings}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              selectedGenres={selectedGenres}
              setSelectedGenres={setSelectedGenres}
              allGenres={allGenres}
              maybeCount={maybeCount}
              definitelyCount={definitelyCount}
            />
          )}

          {activeSection === Sections.UNAVAILABILITY && (
            <FestivalUnavailability
              userId={userId}
              festivalId={festivalId}
              festivalStartDate={festival?.start_date}
              festivalEndDate={festival?.end_date}
              timezone={festivalTimezone}
            />
          )}
          <FestivalDetailContainer>
            {isViewingUserSchedule && userSchedule && !loadingSchedule ? (
              <UserSchedule
                organizedSchedule={userSchedule.schedule}
                attendingFilms={userSchedule.attendingFilms}
                priorityStats={userSchedule.priorityStats}
                festivalTimezone={festivalTimezone}
                scheduleHash={scheduleHash}
                lastUpdated={lastUpdated}
                festivalName={festival?.name}
                scheduleToken={scheduleToken}
              />
            ) : (
              <FilmListContainer>
                <TitleDateContainer>
                  <Title>Films at {festival?.name}</Title>
                  <DateRange>
                    {festival &&
                      formatDateRange(
                        festival.start_date,
                        festival.end_date,
                        festivalTimezone,
                      )}
                  </DateRange>
                </TitleDateContainer>
                <AlphabetNav
                  groupedFilms={groupedFilms}
                  visibleSections={visibleSections}
                />
                <StyledFilmList>
                  {Object.entries(groupedFilms)
                    .sort(([letterA], [letterB]) => {
                      // Ensure alphabetical sorting of sections
                      if (letterA < letterB) return -1;
                      if (letterA > letterB) return 1;
                      return 0;
                    })
                    .map(([letter, filmsInSection]) => {
                      // Apply all filters
                      const filteredFilms = filmsInSection
                        .filter((film) =>
                          showZeroScreenings
                            ? film.screenings.length > 0
                            : true,
                        )
                        .filter((film) =>
                          film.title
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase()),
                        )
                        .filter(
                          (film) =>
                            selectedGenres.size === 0 ||
                            film.genres.some((g) =>
                              selectedGenres.has(g.genre_id),
                            ),
                        )
                        .slice(0, displayCount);

                      // Apply custom sorting if it's the '#' section
                      if (letter === "#") {
                        filteredFilms.sort(sortFilmsByTitleIgnoringThe);
                      }

                      // Skip rendering the section if no films are visible after filtering
                      if (filteredFilms.length === 0) return null;

                      return (
                        <LetterHeaderScrollContainer key={letter} id={letter}>
                          <StyledLetterHeader>
                            {letter.match(/[A-Z]/) ? letter : "#"}
                          </StyledLetterHeader>
                          {filteredFilms.map((film, index) => (
                            <FilmListItem
                              key={film.film_id}
                              film={film}
                              userId={String(userId)}
                              priority={
                                filmPriorities[film.film_id] !== undefined
                                  ? filmPriorities[film.film_id]
                                  : 5
                              }
                              onPriorityChange={handlePriorityChange}
                            />
                          ))}
                        </LetterHeaderScrollContainer>
                      );
                    })}
                </StyledFilmList>
              </FilmListContainer>
            )}
          </FestivalDetailContainer>
        </>
      )}
    </div>
  );
}

export default FestivalDetail;
