import { createGlobalStyle } from "styled-components";

export const theme = {
  colors: {
    primary: "#6200ea",
    secondary: "#03dac6",
    textPrimary: "#000",
    textSecondary: "#000",
    mainBg: "#F2F2F2",
    sectionBg: "#D9D9D9",
    listItemBg1: "#FFFFFF",
    listItemBg2: "#f8f8f8",
  },
  fontSizes: {
    small: "1rem", // 16px
    medium: "1.2rem", // 19.2px
    large: "1.5rem", // 24px
    h3: "1.5rem", // 24px for h3 specifically
  },
  fontWeights: {
    normal: "400",
    bold: "700",
    black: "900",
  },
  fonts: {
    primary: "Roboto, sans-serif",
  },
  // ... add more variables as needed
};

export const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${(props) => props.theme.colors.mainBg};
    margin: 0;
    padding: 0;
    font-family: ${(props) => props.theme.fonts.primary};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  // You may want to add other global styles here
`;
