import React from "react";
import { FeedbackButton } from "./Feedback.styled";
import FeedbackIcon from "@mui/icons-material/Feedback";

const Feedback = () => {
  return (
    <FeedbackButton
      href="https://forms.gle/Bsok1wsaKgo8i4Hg9"
      target="_blank"
      title="Give us your feedback! (Opens in a new tab)"
      rel="noopener noreferrer"
    >
      <FeedbackIcon style={{ color: "white" }} />
    </FeedbackButton>
  );
};

export default Feedback;
