import { React, useState } from "react";
import FestivalStats from "./FestivalStats";
import { PreferencesContainer, StatsContainer, CheckboxContainer } from './FestivalPreferences.styled';


function FestivalPreferences({
  showZeroScreenings,
  toggleShowZeroScreenings,
  searchTerm,
  setSearchTerm,
  selectedGenres,
  setSelectedGenres,
  allGenres,
  maybeCount,
  definitelyCount,
}) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // New state for dropdown toggle

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const toggleGenre = (genreId) => {
    const newSelectedGenres = new Set(selectedGenres);
    if (newSelectedGenres.has(genreId)) {
      newSelectedGenres.delete(genreId);
    } else {
      newSelectedGenres.add(genreId);
    }
    setSelectedGenres(newSelectedGenres);
  };

  const selectAllGenres = () => {
    setSelectedGenres(new Set(allGenres.map((g) => g.genre_id)));
  };

  const selectNoGenres = () => {
    setSelectedGenres(new Set());
  };

  return (
    <PreferencesContainer>
    <StatsContainer>
      <FestivalStats
        maybeCount={maybeCount}
        definitelyCount={definitelyCount}
      />
    </StatsContainer>
    <CheckboxContainer>
      <label>
        <input
          type="checkbox"
          checked={showZeroScreenings}
          onChange={toggleShowZeroScreenings}
        />
        Hide Short Films
      </label>
    </CheckboxContainer>
      {/* <div>
        <button onClick={toggleDropdown}>
          Filter by Genre {isDropdownOpen ? '▲' : '▼'}
        </button>
        {isDropdownOpen && (
          <div className="dropdown-content">
            <button onClick={selectAllGenres}>Select All</button>
            <button onClick={selectNoGenres}>Select None</button>
            {allGenres.map((genre) => (
              <label key={genre.genre_id}>
                <input
                  type="checkbox"
                  checked={selectedGenres.has(genre.genre_id)}
                  onChange={() => toggleGenre(genre.genre_id)}
                />
                {genre.name}
              </label>
            ))}
          </div>
        )}
      </div> */}
  </PreferencesContainer>
  );
}

export default FestivalPreferences;
