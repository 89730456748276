import React, { createContext, useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
} from "firebase/auth";
import { auth } from "../firebase";

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userId, setUserId] = useState(null);
  const [token, setToken] = useState(null);

  function signUp(email, password) {
    return createUserWithEmailAndPassword(auth, email, password);
  }

  function logIn(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }

  const logOut = async () => {
    await auth.signOut();
    setUserId(null);
    setToken(null);
  };

  async function fetchUserIdAndToken(user) {
    try {
      const fetchedToken = await user.getIdToken(true);
      setToken(fetchedToken);

      const res = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/users/firebase/${user.uid}`,
        {
          headers: {
            Authorization: `Bearer ${fetchedToken}`,
          },
        },
      );

      if (!res.ok) {
        throw new Error(`Server responded with ${res.status}`);
      }

      const data = await res.json();
      setUserId(data.user_id);
    } catch (error) {
      console.error("Failed to fetch user_id:", error);
    }
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      if (user) {
        fetchUserIdAndToken(user);
      } else {
        setUserId(null);
        setToken(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const value = {
    currentUser,
    signUp,
    logIn,
    logOut,
    userId,
    token,
    fetchUserIdAndToken
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
