import styled from "styled-components";

export const NavContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 20px 0;
  overflow-x: auto; // Enable horizontal scrolling if necessary
  padding: 5px 5px;
  flex-wrap: nowrap;
  background-color: #fff;
  border-radius: 5px;

  @media (max-width: 768px) {
    margin: 0;
  }
`;

export const LetterButton = styled.button`
  flex: 1; // Distribute space evenly
  text-align: center; // Center the text in the button
  font-weight: 700;
  background: none;
  border: none;
  cursor: pointer;
  margin: 0; // Remove margin to ensure even distribution
  padding: 5px 10px;
  font-size: 16px;
  border-radius: 5px;
  transition: background-color 0.2s;

  &:hover {
    background-color: ${({ disabled }) =>
      disabled ? "transparent" : "#f9da1c"};
  }

  &:disabled {
    color: grey;
    cursor: default;
  }
`;
