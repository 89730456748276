import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';

const Loader = ({ loading }) => {
  return (
    <div style={loaderContainerStyle}>
      <ClipLoader loading={loading} size={50} />
    </div>
  );
};

const loaderContainerStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh', // Adjust this to control the loader's vertical position
};

export default Loader;
