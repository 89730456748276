import styled from "styled-components";

export const FilmDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  margin: 20px auto;
  width: 500px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

  @media (max-width: 600px) {
    width: 90%; /* Adjust width for smaller screens */
    margin: 10px auto; /* Reduce margin */
  }
`;

export const FilmImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 5px;
  margin-bottom: 20px;
`;

export const FilmTitle = styled.h1`
  font-weight: 900;
`;

export const ScreeningsList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  text-align: center;

  li {
    margin-bottom: 15px;
  }
`;
