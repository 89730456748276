import styled from "styled-components";

export const Footer = styled.footer`
  border-top: 1px solid #e0e0e0;
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    text-align: center;
    justify-content: center;
  }
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  @media (max-width: 768px) {
    align-items: center;
    flex-direction: column;
    margin-bottom: 10px;
    gap: 5px;
  }
`;

export const RightContainer = styled.div`
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const FooterText = styled.p`
  margin: 0;
  color: #666;
  font-size: 0.9rem;

  &:first-child {
    padding-left: 0;
  }
`;

export const FooterLinkContainer = styled.div`
  padding-left: 15px;
  display: flex;
  gap: 15px; // Adjust the gap as needed
`;

export const FooterLink = styled.a`
  font-size: 0.9rem;
  color: #666; // Match the color with FooterText or style as needed
  text-decoration: none;
  &:hover {
    text-decoration: underline; // Optional: style for hover state
  }
`;
