import styled from 'styled-components';

export const FestivalListContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto; // Centers the container
  padding: 20px; // Add padding if you want some space inside the container
  min-height: 87vh;
`;

export const FestivalListTitle = styled.h2`
  color: #333;
  font-size: 2rem;
  margin-bottom: 20px; // Space between title and list
`;

export const FestivalListWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
`;

export const ErrorMessage = styled.div`
  color: #ff6b6b; // Error color, you can choose what you prefer
  font-size: 1rem;
  text-align: center; // Center the error message
`;

export const TitleButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SuggestFestivalButton = styled.a`
  background-color: #aeecb1; // Example button color, feel free to change
  color: #000;
  border: 1.5px solid #83b586 ;
  padding: 10px 20px;
  text-align: center;
  font-weight: 400;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 5px;

  &:hover {
    background-color: #a5d5a7; // Adjust to your preferred hover color
    border-color: #5c7e5f;
  }
`;
