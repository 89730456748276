import React from "react";
import PropTypes from "prop-types";
import {
  SchedulePreferencesContainer,
  TitleContainer,
  ControlsContainer,
} from "./SchedulePreferences.styled";

const SchedulePreferences = ({ preferences, setPreferences }) => {
  // Handle change for buffer time
  const handleBufferTimeChange = (e) => {
    setPreferences({
      ...preferences,
      bufferTime: parseInt(e.target.value, 10),
    });
  };

  // Handle change for popularity buffer
  const handlePopularityBufferChange = (e) => {
    setPreferences({
      ...preferences,
      applyPopularityBuffer: e.target.checked,
    });
  };

  // Handle change for include unprioritized
  const handleIncludeUnprioritizedChange = (e) => {
    setPreferences({
      ...preferences,
      includeUnprioritized: e.target.checked,
    });
  };

  return (
    <SchedulePreferencesContainer>
      <TitleContainer>
        <div>Schedule Generation Preferences</div>
      </TitleContainer>
      <ControlsContainer>
        <label htmlFor="bufferTime">Default Buffer Time:</label>
        <select
          id="bufferTime"
          value={preferences.bufferTime}
          onChange={handleBufferTimeChange}
        >
          <option value="30">30 minutes</option>
          <option value="45">45 minutes</option>
          <option value="60">60 minutes</option>
          <option value="75">75 minutes</option>
          <option value="90">90 minutes</option>
        </select>
        <label>
          <input
            type="checkbox"
            checked={preferences.includeUnprioritized}
            onChange={handleIncludeUnprioritizedChange} // Use the handler here
          />
          Include Unprioritized Films
        </label>

        <label>
          <input
            type="checkbox"
            checked={preferences.applyPopularityBuffer}
            onChange={handlePopularityBufferChange}
          />
          Popularity Buffer
        </label>
      </ControlsContainer>
    </SchedulePreferencesContainer>
  );
};

SchedulePreferences.propTypes = {
  preferences: PropTypes.shape({
    bufferTime: PropTypes.number.isRequired,
    includeUnprioritized: PropTypes.bool.isRequired,
    applyPopularityBuffer: PropTypes.bool.isRequired,
  }).isRequired,
  setPreferences: PropTypes.func.isRequired,
};

export default SchedulePreferences;
