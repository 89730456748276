import React, { useState, useEffect } from "react";
import FestivalListItem from "./FestivalListItem";
import Loader from "../common/Loader";
import {
  FestivalListContainer,
  FestivalListTitle,
  FestivalListWrapper,
  ErrorMessage,
  SuggestFestivalButton,
  TitleButtonContainer,
} from "./FestivalList.styled";

function FestivalList() {
  const [festivals, setFestivals] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/api/festivals`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch festivals");
        }
        return response.json();
      })
      .then((data) => {
        // Sort the festivals in reverse order of their start_date
        const sortedFestivals = data.sort((a, b) =>
          b.start_date.localeCompare(a.start_date),
        );
        setFestivals(sortedFestivals);
        setIsLoading(false);
      })
      .catch((fetchError) => {
        console.error("Error fetching festivals:", fetchError);
        setError("Failed to load festivals.");
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <FestivalListContainer>
      <TitleButtonContainer>
        <FestivalListTitle>Festivals</FestivalListTitle>
        <SuggestFestivalButton
          href="https://forms.gle/GRXhihbWdKMzpECNA"
          target="_blank"
          rel="noopener noreferrer"
        >
          Suggest a Festival
        </SuggestFestivalButton>
      </TitleButtonContainer>
      {festivals.length === 0 ? (
        <p>No festivals available at the moment.</p>
      ) : (
        <FestivalListWrapper>
          {festivals.map((festival) => (
            <FestivalListItem key={festival.festival_id} festival={festival} />
          ))}
        </FestivalListWrapper>
      )}
    </FestivalListContainer>
  );
}

export default FestivalList;
