import styled from "styled-components";

export const NavContainer = styled.nav`
  background-color: white; // White background
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); // Optional: adds a subtle shadow to the navbar
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  padding: 1rem 2rem;
  margin-top: -0.99rem;
  top: 0;
  z-index: 1000;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: stretch; // Stretch children to fill the width
    padding: 0rem 1rem;
    padding-top: 0.5rem;
  }
`;

export const FestivalName = styled.div`
  font-size: 1.5rem; // Adjust the size as needed
  font-weight: 900; // If you want the festival name to be bold

  @media (max-width: 768px) {
    display: none;
  }
`;

export const NavControls = styled.div`
  display: flex;
  align-items: center; // Align the controls vertically

  @media (max-width: 768px) {
    flex-wrap: wrap; // Allow items to wrap onto the next line
    justify-content: space-between; // Space out controls
    gap: 0.5rem; // Add a small gap between wrapped items
  }
`;

export const IconContainer = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  padding: 8px;
  margin: 0 2px;
  color: #000; // Default icon color
  font-size: 24px; // 24dp size for icons
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};

  &:hover {
    background-color: ${(props) =>
      props.disabled ? "transparent" : "#e9e9e9"};
  }

  &.active {
    background-color: #000; // Background color when active
    color: #fff; // Icon color when active
  }
`;

export const SearchIconContainer = styled.div`
  display: flex;
  align-items: center;
  color: #000000;

  svg {
    // This styles the SearchIcon specifically
    margin-right: 0.5rem; // Adds space between the icon and the input field
  }
`;

export const SearchInput = styled.input`
  padding: 0.5rem 0; // Padding on top and bottom only
  border: none;
  border-bottom: 1.5px solid #000; // Remove the default border
  outline: none; // Remove the default outline
  margin-right: 10px;
  width: 12rem;

  &:placeholder-shown {
    color: #717171; // Placeholder text is red only when placeholder is shown
  }

  &:not(:placeholder-shown) {
    color: #000; // Text color is black when user is typing
  }

  &::placeholder {
    color: inherit; // Ensures the placeholder color is inherited from the :placeholder-shown state
  }

  &:focus {
    color: #000; // Ensure text is black when input is focused
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 0.2rem 0;
  }
`;

export const GenerateScheduleButton = styled.button`
  background-color: #000000;
  color: #ffffff;
  border: none;
  padding: 0.75rem 1.25rem;
  cursor: pointer;
  border-radius: 5px;
  margin-left: 0.25rem;
  font-weight: bold; // Optional: if you want the text to be bold
  text-transform: uppercase; // Optional: if you want the text to be uppercase
  /* transition: background-color 0.3s ease; // Smooth transition for hover effect */
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};

  &:hover {
    background-color: ${(props) => (props.disabled ? "#000000" : "#e8e8e8")};
    color: ${(props) => (props.disabled ? "#ffffff" : "#000")};
  }

  // You may want to add focus styles if you're focusing on accessibility
  &:focus {
    outline: none;
    // Optionally add a style for the focus state
  }

  @media (max-width: 768px) {
    flex-grow: 1; // Allow button to take up available space
    margin: 0rem 0 0.5rem 0;
  }
`;

export const ButtonGroupContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem; // Adjust the gap between buttons if needed

  @media (max-width: 768px) {
    width: 100%; // Take full width
    justify-content: space-between; // Space out buttons
    margin-bottom: 0.5rem; // Add space below for the next row of elements
  }
`;

export const FilmListButton = styled.button`
  background-color: #e6e6e6;
  color: #000000;
  border: ${(props) => (props.isSelected ? "2px solid #000000" : "none")};
  padding: 0.75rem 1.25rem;
  cursor: pointer;
  border-radius: 5px;
  margin-left: 0.5rem;
  font-weight: bold;
  text-transform: uppercase;
  // transition: 0.15s ease;

  &:hover {
    color: #181818;
    background-color: #c6c6c6;
  }

  &:disabled {
    background-color: #ffffff;
    color: #c0c0c0;
    cursor: not-allowed;
    border-color: #c0c0c0;
  }

  &:focus {
    outline: none;
    // Define focus styles here if needed
  }

  @media (max-width: 768px) {
    margin-left: 0;
    width: calc(50% - 5px);
  }
`;

export const ScheduleButton = styled(FilmListButton)`
  background-color: ${(props) =>
    props.scheduleGenerated ? "e6e6e6" : "#ffffff"};
  color: ${(props) => (props.scheduleGenerated ? "#000000" : "#a5a5a5")};
  cursor: ${(props) =>
    props.isSelected || props.scheduleGenerated ? "pointer" : "default"};
  border-color: ${(props) =>
    props.isSelected || props.scheduleGenerated ? "#000000" : "#a5a5a5"};
  margin-left: 0;

  &:hover {
    border-color: ${(props) =>
      props.scheduleGenerated ? "#000000" : "#a5a5a5"};
    color: ${(props) => (props.scheduleGenerated ? "#000000" : "#a5a5a5")};
  }

  &:disabled {
    background-color: #ffffff;
    color: #c0c0c0;
    cursor: not-allowed;
    border-color: #c0c0c0;
  }

  @media (max-width: 768px) {
    width: calc(50% - 5px);
  }
`;
