/* eslint-disable consistent-return */
import React, { useRef, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import {
  SignUpPageContainer,
  ImageContainer,
  SignUpSection,
  HeaderText,
  Input,
  SubmitButton,
  SignUpText,
  SignUpLink,
  CheckboxLabel,
  Checkbox,
  TermsLink,
} from "./SignUp.styled";

function SignUp() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();
  const { signUp, fetchUserIdAndToken } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const usernameRef = useRef();
  const navigate = useNavigate();
  let user;

  async function handleSubmit(event) {
    event.preventDefault();

    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError("Passwords do not match");
    }

    if (!agreedToTerms) {
      return setError(
        "You must agree to the Terms & Conditions and Privacy Policy to sign up.",
      );
    }

    try {
      setError("");
      setLoading(true);
      const result = await signUp(
        emailRef.current.value,
        passwordRef.current.value,
      );
      user = result.user;

      // Now, make a POST request to your API to store the user details.
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/users`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            firebase_uid: user.uid,
            username: usernameRef.current.value,
          }),
        },
      );

      if (!response.ok) {
        throw new Error("Failed to save user to database.");
      }

      // Redirect to /festivals page after successful sign-up
      navigate("/festivals");
    } catch (err) {
      console.error("Sign Up Error:", err);
      if (err.code === "auth/email-already-in-use") {
        setError("The email address is already in use by another account.");
      } else {
        setError("Failed to create an account");
      }
    }

    setLoading(false);
    if (user) {
      fetchUserIdAndToken(user);
    }
  }

  return (
    <SignUpPageContainer>
      <ImageContainer /> {/* Styled image container */}
      <SignUpSection>
        <HeaderText>Sign Up for Festiv</HeaderText>
        {error && <p>{error}</p>}
        <form onSubmit={handleSubmit}>
          <div>
            <Input type="email" ref={emailRef} required placeholder="Email" />
          </div>
          <div>
            <Input
              type="text"
              ref={usernameRef}
              required
              placeholder="Username"
            />
          </div>
          <div>
            <Input
              type="password"
              ref={passwordRef}
              required
              placeholder="Password"
            />
          </div>
          <div>
            <Input
              type="password"
              ref={passwordConfirmRef}
              required
              placeholder="Confirm Password"
            />
          </div>
          <div>
            <CheckboxLabel>
              <Checkbox
                type="checkbox"
                checked={agreedToTerms}
                onChange={(e) => setAgreedToTerms(e.target.checked)}
              />
              I agree to the&nbsp;
              <TermsLink
                href="https://app.termly.io/document/terms-of-service/ad173f91-af61-43d7-87ae-f9a9f92a5c4c"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms & Conditions
              </TermsLink>&nbsp;and&nbsp;
              <TermsLink
                href="https://app.termly.io/document/privacy-policy/06133029-c29f-4bb9-b219-f8a207c304ca"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </TermsLink>
              .
            </CheckboxLabel>
          </div>
          <SubmitButton disabled={loading} type="submit">
            Sign Up
          </SubmitButton>
        </form>
        <SignUpText>
          Already have an account? <SignUpLink to="/login">Log in</SignUpLink>
        </SignUpText>
      </SignUpSection>
    </SignUpPageContainer>
  );
}

export default SignUp;
