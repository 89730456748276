import React, { useRef, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { auth } from "../../firebase";
import {
  LoginPageContainer,
  ImageContainer,
  LogInSection,
  HeaderText,
  Input,
  SubmitButton,
  SignUpText,
  SignUpLink,
} from "./LogIn.styled";

function LogIn() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const { logIn, fetchUserId } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState("");
  const navigate = useNavigate();

  async function handleSubmit(event) {
    event.preventDefault();
    try {
      setError("");
      setLoading(true);
      await logIn(emailRef.current.value, passwordRef.current.value);

      // If login is successful, navigate to the /festivals page
      navigate("/festivals");
    } catch {
      setError("Failed to log in");
    }
    setLoading(false);
  }

  return (
    <LoginPageContainer>
      <ImageContainer />
      <LogInSection>
        <HeaderText>Log In to Festiv</HeaderText>
        {error && <p>{error}</p>}
        {success && <p style={{ color: "green" }}>{success}</p>}
        <form onSubmit={handleSubmit}>
          <div>
            <Input type="email" ref={emailRef} required placeholder="Email" />
          </div>
          <div>
            <Input
              type="password"
              ref={passwordRef}
              required
              placeholder="Password"
            />
          </div>
          <SubmitButton disabled={loading} type="submit">
            Log In
          </SubmitButton>
        </form>
        <SignUpText>
          Don't have an account? <SignUpLink to="/signup">Sign up</SignUpLink>
        </SignUpText>
      </LogInSection>
    </LoginPageContainer>
  );
}

export default LogIn;
